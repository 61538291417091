import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { filterLanguage, searchTerm } from "../module/Notify";
import { withTranslation } from "react-i18next";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { NavLink } from "react-router-dom";


class Navigation extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      dropdownOpen: false,
      language: "en",
      NavBarData: [],
      channelData: [],
      webseriesData: [],
      hidenavbar: false,
      search_term: "",
    };
  }

  handleScroll = () => {
    if (window.scrollY > "130") {
      this.setState({
        hidenavbar: true,
      });
    }
    if (window.scrollY < "30") {
      this.setState({
        hidenavbar: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const url = this.props.location.pathname;
      const url_array = url.split("/");

      window.addEventListener("SidenavImgClick", () => {
        this.setState({
          hidenavbar: false,
        });
      });

      if (
        url_array[1] === "playlist" ||
        url_array[1] === "video" ||
        url_array[1] === "tag"
      ) {
        window.removeEventListener("scroll", this.handleScroll);
        this.setState({
          hidenavbar: true,
        });
      } else {
        window.addEventListener("scroll", this.handleScroll);
      }
      // fetch or other component tasks necessary for rendering
    }
  }

  UNSAFE_componentWillMount() {
    const url = this.props.location.pathname;
    const url_array = url.split("/");

    window.addEventListener("SidenavImgClick", () => {
      // console.log("logo clciked navbar");
      this.setState({
        hidenavbar: false,
      });
    });

    if (
      url_array[1] === "playlist" ||
      url_array[1] === "video" ||
      url_array[1] === "tag"
    ) {
      // console.log("inside navbar hide");
      this.setState({
        hidenavbar: true,
      });
    } else if (
      url_array[1] !== "playlist" &&
      url_array[1] !== "video" &&
      url_array[1] !== "tag"
    ) {
      window.addEventListener("scroll", this.handleScroll);
    }

    const getLang = window.localStorage.getItem("language") || "en";
    // this.fetchNavBarData(getLang);
    this.handleChange(getLang);
  }
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  handleChange = (val) => {
    // console.log("language",val);
    this.setState({
      language: val,
    });
    filterLanguage(val);
    localStorage.setItem("language", val);
    this.fetchNavBarData(val);
    const { i18n } = this.props;
    i18n.changeLanguage(val);
  };

  fetchNavBarData = (lang) => {
    var networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.get_navbar_links_data);
    networkHelper.setMethod("POST");
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      })
    );
    networkHelper.execute(
      (response) => {
        // console.log("Nav bar Page Data >>>", response.data.data)
        this.setState({
          channelData: response.data.data.channel,
          webseriesData: response.data.data.webseries,
        });
      },
      function (errorMsg) {
        console.log(errorMsg);
      },
      function () {}
    );
  };

  render() {
    const { channelData, webseriesData, hidenavbar } = this.state;
    return (
      <Navbar
        bg="transparent"
        expand="lg"
        className={hidenavbar === true ? "bs-header nav-hide" : "bs-header"}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={require("../../images/bhadipa-logo.png")}
              alt="Bhadipa Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="top-nav">
              <Form
                inline
                className="search-box"
                onSubmit={(e) => {
                  this.props.history.location !== null &&
                  this.props.history.location !== undefined
                    ? this.props.history.location.pathname === "/search"
                      ? e.preventDefault()
                      : this.props.history.push("/search")
                    : this.props.history.push("/search");
                }}
              >
                <FormControl
                  type="text"
                  placeholder="Find videos, channels, series, music & more..."
                  className="mr-sm-2"
                  onChange={(e) => {
                    searchTerm(e.target.value);
                    if (e.target.value !== "") {
                      window.localStorage.setItem("search", e.target.value);
                    }
                    this.setState({
                      search_term: e.target.value,
                    });
                  }}
                />
                <Button
                  variant="link"
                  onClick={() => {
                    this.props.history.push("/search");
                  }}
                  className="icon-search"
                ></Button>
              </Form>
              <Nav activeKey="/home">
                <Nav.Item>
                  <Nav.Link as={Link} to="/contact">
                    {this.props.t("navbar.touch", {
                      framework: "Get in Touch",
                    })}
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-1">
                    {this.props.t("navbar.signup", { framework: "Sign Up" })}
                  </Nav.Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    className="yellow-link"
                    as={Link}
                    to="/login"
                  >
                    {" "}
                    {this.props.t("navbar.login", { framework: "Login" })}
                  </Nav.Link>
                </Nav.Item> */}
                <div className="language-box">
                  <Nav.Item>
                    <Nav.Link
                      className={
                        this.state.language === "en" ? "active" : "link-1"
                      }
                      onClick={() => this.handleChange("en")}
                      eventKey="link-2"
                    >
                      Eng
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={
                        this.state.language === "ma" ? "active" : "link-2"
                      }
                      eventKey="link-2"
                      onClick={() => this.handleChange("ma")}
                    >
                      मराठी
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Nav>
            </div>
            <Nav className="mr-auto bottom-nav">
              {channelData !== null
                ? channelData.map((nav, index) => (
                    <li key={index} className="nav-item dropdown">
                      <Link
                        activeClassName="active"
                        className="dropdown-toggle nav-link"
                        to={`/channel/${nav.channel_slug}`}
                        id={`navbarDropdown${index}`}
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        {/* <a className="nav-link dropdown-toggle" href={`https://creativewebo.in/clients/bhadipa-html/channel/${nav.id}`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                        {/* <Link to={`/channel/${nav.channel_slug}`}> */}
                        {nav.title}
                        {/* </Link> */}
                        {/* </a> */}
                      </Link>
                      <div
                        className="dropdown-menu mega-drop"
                        aria-labelledby="navbarDropdown"
                      >
                        {/* <Row>
                          <Col lg={6}>
                            {nav.subcategory !== null
                              ? nav.subcategory
                                  .slice(0, nav.subcategory.length / 2)
                                  .reverse()
                                  .map((sub, index2) => (
                                    <NavDropdown.Item key={index2}>
                                      <Link to={`/playlist/${sub.id}`}>
                                        {sub.name}
                                      </Link>
                                    </NavDropdown.Item>
                                  ))
                              : null}
                          </Col>
                          <Col lg={6}>
                            {nav.subcategory !== null
                              ? nav.subcategory
                                  .slice(
                                    nav.subcategory.length / 2,
                                    nav.subcategory.length
                                  )
                                  .reverse()
                                  .map((sub, index3) => (
                                    <NavDropdown.Item key={index3}>
                                      <Link to={`/playlist/${sub.id}`}>
                                        {sub.name}
                                      </Link>
                                    </NavDropdown.Item>
                                  ))
                              : null}
                          </Col>
                        </Row> */}
                        <Row>
                          <Col lg={4}>
                            {nav.subcategory !== null
                              ? nav.subcategory
                                  .reverse()
                                  .slice(0, nav.subcategory.length / 3)
                                  .map((sub, index2) => (
                                    <NavDropdown.Item
                                      key={index2}
                                      href={`/playlist/${sub.id}`}
                                    >
                                      {sub.name}
                                    </NavDropdown.Item>
                                  ))
                              : null}
                          </Col>
                          <Col lg={4}>
                            {nav.subcategory !== null
                              ? nav.subcategory
                                  .reverse()
                                  .slice(
                                    nav.subcategory.length / 3,
                                    (nav.subcategory.length * 2) / 3
                                  )
                                  .map((sub, index3) => (
                                    <NavDropdown.Item
                                      key={index3}
                                      href={`/playlist/${sub.id}`}
                                    >
                                      {sub.name}
                                    </NavDropdown.Item>
                                  ))
                              : null}
                          </Col>
                          <Col lg={4}>
                            {nav.subcategory !== null
                              ? nav.subcategory
                                  .reverse()
                                  .slice(
                                    (nav.subcategory.length * 2) / 3,
                                    nav.subcategory.length
                                  )
                                  .map((sub, index4) => (
                                    <NavDropdown.Item
                                      key={index4}
                                      href={`/playlist/${sub.id}`}
                                    >
                                      {sub.name}
                                    </NavDropdown.Item>
                                  ))
                              : null}
                          </Col>
                        </Row>
                      </div>
                    </li>
                  ))
                : null}

              <li className="nav-item dropdown">
                <Link
                  activeClassName="active"
                  className="dropdown-toggle nav-link"
                  to={`/webseries`}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.t("navbar.webseries", {
                    framework: "Web Series",
                  })}
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {webseriesData !== null
                    ? webseriesData.map((nav, index) => (
                        <NavDropdown.Item
                          key={index}
                          href={nav.external_link}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(nav.external_link, "_blank");
                          }}
                        >
                          {nav.title}
                        </NavDropdown.Item>
                      ))
                    : null}
                </div>
              </li>

              {/* <Nav.Link href="https://bit.ly/31c1Ync" target="_blank">
								{this.props.t("navbar.shop", { framework: "Shop Now" })}
							</Nav.Link> */}
              <a href="https://bhadipa.merchgarage.com/" target="_blank">
                {/* {this.props.t("navbar.Talent", { framework: "Talent" })} */}
                {this.state.language === "en" ? "Shop Now" : "आता खरेदी करा"}
              </a>
              <li className="nav-item dropdown">
                <NavLink
                  activeClassName="active"
                  className="nav-link dropdown-toggle"
                  to={`/events`}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.t("navbar.events", { framework: "Events" })}
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <NavDropdown.Item as={Link} to="/events">
                    {this.props.t("navbar.event.link1", {
                      framework: "Events",
                    })}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/eventformats">
                    {this.props.t("navbar.event.link2", {
                      framework: "Event Formats",
                    })}
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/comedians">
										{this.props.t("navbar.event.link3", {
											framework: "comedians",
										})}
									</NavDropdown.Item> */}
                </div>
              </li>

              {/* <li className="nav-item dropdown">
								<a className="nav-link dropdown-toggle" href="/events" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{/* {this.props.t( { framework: "Comedian" })} */}
              {/* comedians
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<a>
								<NavDropdown.Item as={Link} to="/comedians">
										{/* {this.props.t("navbar.event.link1", {
											framework: "Events",
										})} */}
              {/* Creators & Actors (instead of influencers)
									</NavDropdown.Item>
								</a>
								
									<NavDropdown.Item as={Link} to="/comedians">
										{/* {this.props.t("navbar.event.link2", {
											framework: "Event Formats",
										})} */}
              {/* Talent
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/comedians">
										{/* {this.props.t("navbar.event.link3", {
											framework: "comedians",
										{/* })} */}
              {/* Writers & Directors
									</NavDropdown.Item>
								</div>
							</li>  */}
              {/* <NavLink as={Link} to="/talent" activeClassName="active">
                {this.state.language === "en" ? "Talent" : "प्रतिभा"}
              </NavLink> */}
              {/* {console.log("tis.state.talent" , this.state.language )} */}
              <NavLink as={Link} to="/about" activeClassName="active">
                {this.props.t("navbar.About", { framework: "About" })}
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
export default withTranslation("common")(withRouter(Navigation));
