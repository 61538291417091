import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/footer-logo.png";
import { Container, Row, Col, Image, Nav } from "react-bootstrap";
import { changeTabFilter } from "../module/Notify";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCareer: false,
      lang: "en",
    };
    this.changeTab = this.changeTab.bind(this);
  }

  changeLang = (val) => {
    const { i18n } = this.props;
    i18n.changeLanguage(val);
  };

  changeTab = (value) => {
    changeTabFilter(value);
    window.localStorage.setItem("tab", value);
  };

  UNSAFE_componentWillMount() {
    if (!window.localStorage.getItem("language") != null) {
      const getLang = window.localStorage.getItem("language") || "en";
      this.changeLang(getLang);
      this.setState({
        lang: getLang,
      });
    }
    this.changeTab();
  }
  render() {
    return (
      <footer>
        <div className="bs-footer">
          <Container>
            <Row>
              <Col lg={3} className="foot-logo">
                <a href="/">
                  <figure>
                    {" "}
                    <LazyLoad height={200}>
                      <Image
                        src={logo}
                        className={["img-fluid", "footer-logo"]}
                      />
                    </LazyLoad>
                  </figure>
                </a>
              </Col>

              <Col lg={3} md={4} className="ctm-space">
                <h4>
                  {this.props.t("footer.title", { framework: "Quick links" })}
                </h4>
                <div className="quick-links">
                  <div>
                    <Nav>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          onClick={() => this.changeTab("advertise")}
                          to="/contact"
                        >
                          {this.props.t("footer.link1", {
                            framework: "Advertise",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to="/media">
                          {this.props.t("footer.link2", { framework: "Media" })}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          onClick={() => this.changeTab("careers")}
                          to="/contact"
                        >
                          {this.props.t("footer.link3", {
                            framework: "Career",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to="/terms">
                          {this.props.t("footer.link4", {
                            framework: "Terms of use",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to="/privacy">
                          {this.props.t("footer.link5", {
                            framework: "Privacy policy",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="https://bhadipa.merchgarage.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.t("footer.link6", {
                            framework: "Merchandise",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4} className="top-res">
                <figure>
                  <LazyLoad height={200}>
                    {/* <Image src={hashtag} fluid /> */}
                    <div>
                      <div className="one">
                        <a
                          href="https://www.facebook.com/hashtag/bhadipa/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link-bold"
                        >
                          #Bhadipa
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/vishaykhol/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link"
                        >
                          #vishaykhol
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/jagatbhari/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link-bold"
                        >
                          #jagatbhari
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/missmanners/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link"
                        >
                          #missmanners{" "}
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/Bha2Pa/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link-bold"
                        >
                          #Bha2Pa
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/aaianime/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link"
                        >
                          #Aaianime{" "}
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/Kandepohe/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link-bold"
                        >
                          #Kandepohe
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/hashtag/marathistandupcomedy/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hashtag-link"
                        >
                          #MarathiStandupComedy
                        </a>
                      </div>
                    </div>
                  </LazyLoad>
                </figure>
              </Col>

              <Col lg={3} md={4} className="ctm-space top-res">
                <h4>
                  {this.props.t("footer.follow", { framework: "Follow us on" })}
                </h4>
                <div className="social-icon">
                  <a
                    href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-youtube">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </a>
                  <a
                    href="https://www.facebook.com/BhaDiPa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/bhadipa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-instagram"></i>
                  </a>
                  <a
                    href="https://twitter.com/BhaDiPa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-twitter"></i>
                    {/* <img className="icon" src={require("../../images/clients/x-twitter.svg")}></img> */}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="foot-bottom">
          <Container>
            <Row>
              <Col lg={6} md={6}>
                <p>
                  ©{" "}
                  {this.props.t("footer.copyright", {
                    framework: "Copyright 2023",
                  })}
                </p>
              </Col>
              <Col lg={6} md={6} className="txt-right">
                <p>
                  <span>
                    {this.props.t("footer.by", {
                      framework: "Proudly powered by",
                    })}
                  </span>{" "}
                  <a
                    href="https://www.creativewebo.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Creativewebo
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}
export default withTranslation("common")(Footer);
