import axios from "axios";
let instance = null;

export default class NetworkHelper {
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.url = "";
		this.method = "";
		this.data = "";
		this.contentType = "application/json";
		return instance;
	}

	setUrl(url) {
		this.url = url;
	}
	setMethod(method) {
		this.method = method;
	}
	setData(data) {
		this.data = data;
	}
	setContentType(contentType) {
		this.contentType = contentType;
	}
	execute(successHandler, errorHandler, networkHandler) {
		if (this.method.toLowerCase() == "get") {
			var requestParam = {
				method: this.method,
			};

			axios
				.get(this.url)
				.then(function (response) {
					// handle success
					successHandler(response);
				})
				.catch(function (error) {
					// handle error
					errorHandler("Something went wrong", error);
				})
				.then(function () {
					// always executed
				});
		} else {
			var requestParam = {
				//method : this.method,
				body: this.data,
			};

			axios
				.post(this.url, requestParam.body)
				.then((response) => {
					// console.log(response.status + "REsponse");
					var status = response.status.toString();
					// Utils.logData('status code==============>'+status+'<============================');
					//  Utils.logData('response==============>'+JSON.stringify(response)+'<===============');
					switch (status) {
						case "200":
							if (response._bodyText != "") {
								// response.json().then((responseData) =>
								// {
								successHandler(response);
								//});
							} else {
								successHandler({});
							}
							break;
						case "204":
							successHandler({});
							break;
						case "404":
						case "403":
						case "401":
							errorHandler("Wrong EmployeeId or Password", 401);
							break;
						case "400":
							errorHandler("Wrong EmployeeId or Password", 401);
							break;
						default:
							errorHandler("Wrong EmployeeId or Password", 401);
					}
				})
				.catch(function (err) {
					// console.log(err)
					if (err instanceof TypeError) {
						// console.log(err instanceof TypeError)
						if (err.message == "Network request failed") {
							networkHandler();
						} else {
							if (typeof err.response.status != "undefined") {
								errorHandler("Something went wrong", err.response.status);
							}
						}
					} else {
						if (err.response.status == 401) {
							errorHandler("Wrong Credentials", err.response.status);
						} else {
							errorHandler("Something went wrong", err.response.status);
						}
					}
				});
		}

		//Utils.logData('Final Params  '+requestParam.body);
		//Utils.logData('Final Url  '+this.url);
	}
}
