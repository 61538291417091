import React from 'react'
import Swiper from 'react-id-swiper'

import { Image, Row, Col } from 'react-bootstrap'

const SwiperCreactor = (props) => {
  //   console.log(props.comedians, 'this is prop data')

  const Data = props.writers
  const lang = props.lang
  console.log("lang" , props.lang)
  // console.log(Data, '>>>> this is data')
  const params = {
    slidesPerView: 3,
    spaceBetween: 20,
    centerSlides:true,
    navigation: {
      // nextEl: '.base .channel-desc .ctm-right',
      // prevEl: '.base .channel-desc .ctm-left',
      //   nextEl: '.base .channel-desc .ctm-right',
      //   prevEl: '.base .channel-desc .ctm-left',
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
      autoplay: {
        delay: 3000,
      disableOnInteraction: false
      }
      ,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.base.channel-desc.ctm-right',
          prevEl: '.base.channel-desc.ctm-left',
        },
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
            // nextEl: '.base.channel-desc.ctm-right',
          // prevEl: '.base.channel-desc.ctm-left',
          disableOnInteraction: false
        },
      },
    },
  }

  return (
    <Swiper {...params} shouldSwiperUpdate>
      {
        // console.log("Channel ",Data)
     
        Data != null
          ? // console.log("Data",typeof Data)
          
            Object.keys(Data).map((value) =>
              // console.log("Data ",Data[id]._id)
              Data[value].map((val) => (
                <div
                  className="wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  {/* {console.log( Object.keys(Data).map((value ) ) , "   {console.log( Object.keys(Data).map((value )}" )} */}
                  <div className="bs-box Comedians typ-border-yellow">
                    <figure>
                  
                  { console.log(val.image.en , "val.image.en>>>>>>>>>")}
                  {console.log(lang ,"lang?????")}
                   { lang == 'en' ?  (<Image src={  val.image.en  } fluid className="pic" />) :( <Image src={  val.image.ma  } fluid className="pic" />) }
                  
                    </figure>
                    <h4 className="head">{val.name}</h4>
                    <p>{val.description}</p>
                    <div className="links">
                      {/* {val.video_link != null ? (
                        <a
                          href={val.video_link}
                          target="_blank"
                          className="cm-btn"
                        >
  
                        </a>
                      ) : null} */}
                      {val.video_link != null ? (
										<a href={val.video_link} target="_blank" rel="noopener noreferrer" className="cm-btn">
											{/* {props.t("comedians.video", { framework: "View video" })} */}
                      {
                        (lang == 'en' ? ("IMDB") : ("IMDB")) 
                      }
										</a>
									) : null}
                      {val.twitter_link != null ? (
                        <a
                          href={val.twitter_link}
                          target="_blank"
                          className="social-icon"
                        >
                          <i className="icon icon-twitter"></i>
                        </a>
                      ) : null}
                      {val.instagram_link != null ? (
                        <a
                          href={val.instagram_link}
                          target="_blank"
                          className="social-icon"
                        >
                          <i className="icon icon-instagram"></i>
                        </a>
                      ) : null}
                      {val.fb_link != null ? (
                        <a
                          href={val.fb_link}
                          target="_blank"
                          className="social-icon"
                        >
                          <i className="icon icon-facebook"></i>
                        </a>
                      ) : null}
                      {val.yt_link != null ? (
                        <a
                          href={val.yt_link}
                          target="_blank"
                          className="social-icon"
                        >
                          <i className="icon icon-youtube">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
              ),
            )
          : null
      }
    </Swiper>
  )
}

export default SwiperCreactor 

