import React, { useEffect } from "react";
import Swiper from "react-id-swiper";
import { Image as ImageTag, Row, Col } from "react-bootstrap";
import { Image , Shimmer } from 'react-shimmer'

import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import playlistPlaceHolder from "../../images/playlist-placeholder.png";
const WebSeries = (props) => {
	let webseries = props.webseries;
	console.log(webseries)
	let lang = props.lang;
	const params = {
		slidesPerView: 2,
		spaceBetween: 20,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},

		breakpoints: {
			1024: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 1,
				spaceBetween: 20,
			},
			640: {
				slidesPerView: 1,
				spaceBetween: 20,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		},
	};
	useEffect(() => {});

	return (
		<Swiper {...params} shouldSwiperUpdate>
			{webseries != null ? (
				Object.keys(webseries).reverse().map((id) => (
					<div>
						<Row className="series-slide">
							<Col lg={6}>
								<figure className="wow fadeInUp" data-wow-delay="0.1s">
									<LazyLoad overflow scrollContainer={true} scroll={true} height={200}>
										<Image NativeImgProps={{ className: "img-fluid series-pic" }} fallback={<ImageTag src={playlistPlaceHolder} fluid className="series-pic" />} src={lang === "en" ? webseries[id].banner.en : webseries[id].banner.ma} />
									</LazyLoad>
								</figure>
							</Col>
							<Col lg={6}>
								<div className="series-decp">
									<h5 className="wow fadeInUp">{webseries[id].title}</h5>
									<p className="wow fadeInUp" data-wow-delay="0.2s">
										{webseries[id].description}
									</p>
									<a href={webseries[id].external_link} className="cm-btn wow fadeInUp" data-wow-delay="0.3s">
										{props.t("web.check", { framework: "Check now" })}
									</a>
								</div>
							</Col>
						</Row>
					</div>
				))
			) : (
				<div></div>
			)}
		</Swiper>
	);
};

export default withTranslation("common")(WebSeries);
