import React, { Component } from "react";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";

import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import Instagram from "../../common/Instagram";
class Media extends Component {
	constructor() {
		super();
		this.state = {
			showText: false,
			MediaPageData: [],
			lang: "en",
		};
	}

	//Change the lang using constructor
	changeLang = (val) => {
		const { i18n } = this.props;
		i18n.changeLanguage(val);
		this.getMediaPageData(val);
	};

	// Event emiter for language changed
	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			const { i18n } = this.props;
			this.setState({
				lang: e.detail,
			});
			i18n.changeLanguage(e.detail);
			this.getMediaPageData(e.detail);
		});
	}

	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
		// const wow = new WOW().init();
		if (!window.localStorage.getItem("language") != null) {
			const getLang = window.localStorage.getItem("language") || "en";
			// this.getMediaPageData(getLang);
			this.changeLang(getLang);
			this.setState({
				lang: getLang,
			});
		}
		this.getLanguage();
	}
	getMediaPageData = (lang) => {
		var networkHelper = new NetworkHelper();
		networkHelper.setUrl(Constants.get_media_page_data);
		networkHelper.setMethod("POST");
		networkHelper.setData(
			JSON.stringify({
				lang_code: lang,
			})
		);
		networkHelper.execute(
			(response) => {
				// console.log("Media Page Data >>>", response.data.data)
				this.setState({
					MediaPageData: response.data.data,
				});
			},
			function (errorMsg, StatusCode) {
				console.log(errorMsg);
			},
			function () {}
		);
	};
	render() {
		const { MediaPageData } = this.state;
		return (
			<div>
				<section className="lyt-white honors">
					<Container>
						<Row>
							<Col lg={12} className="txt-center">
								<h2 className="title wow fadeInUp">{this.props.t("media.header", { framework: "MEDIA" })}</h2>
							</Col>
						</Row>
						<Row>
							{MediaPageData != null
								? // console.log("Media  dtaa object >>",MediaPageData.media)
								  Object.keys(MediaPageData.slice(0, 6)).map((id) => (
										// console.log(" media Desc", MediaPageData.media[id].en_media_description)
										<Col lg={6}>
											<article className="bs-box news cm-shadow wow fadeInUp" data-wow-delay="0.1s">
												<h3 className="head">{MediaPageData[id].title}</h3>
												<p>{MediaPageData[id].description}</p>
												<a href={MediaPageData[id].external_link} className="cm-btn" target="_blank" rel="noopener noreferrer">
													{this.props.t("video_details.read_more", { framework: "Read More" })}
												</a>
												<p className="source">Source: {MediaPageData[id].source}</p>
											</article>
										</Col>
								  ))
								: ""}

							<Collapse in={this.state.showText}>
								<Row>
									{MediaPageData.slice(6, MediaPageData.length) != null
										? Object.keys(MediaPageData.slice(0, 6)).map((id) => (
												<Col lg={6}>
													<article className="bs-box news cm-shadow wow fadeInUp" data-wow-delay="0.1s">
														<h3 className="head">{MediaPageData[id].title}</h3>
														<p>{MediaPageData[id].description}</p>
														<a href={MediaPageData[id].external_link} className="cm-btn" target="_blank" rel="noopener noreferrer">
															{this.props.t("video_details.read_more", { framework: "Read More" })}
														</a>
														<p className="source">Source: {MediaPageData[id].source}</p>
													</article>
												</Col>
										  ))
										: ""}
								</Row>
							</Collapse>

							<Col lg={12} className="txt-center wow fadeInUp">
								<a onClick={() => this.setState({ showText: !this.state.showText })} className="cm-btn yellow bg-shadow">
									{this.state.showText ? this.props.t("media.see_less", { framework: "See less" }) : this.props.t("media.see_more", { framework: "See More" })}
								</a>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="lyt-social">
					<Container fluid={true}>
						<Row>
							<Col md={12} lg={12} className="bg-base">
								<h2 className="title wow fadeInUp footer-text" data-wow-delay="0.1s">
									{this.props.t("social.watch", { framework: "Watch us on" })}
								</h2>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ" data-wow-delay="0.2s">
									<figure>
										<LazyLoad height={200}>
											<Image src={youtube} fluid />
										</LazyLoad>
									</figure>
								</a>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BhaDiPa/" data-wow-delay="0.3s">
									<figure>
										<LazyLoad height={200}>
											<Image src={facebook} fluid />
										</LazyLoad>
									</figure>
								</a>
								<Instagram />
								<a href="/contact" className="cm-btn bg-shadow wow fadeInUp" data-wow-delay="0.5s">
									{this.props.t("social.get", { framework: "Get In Touch" })}
								</a>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default withTranslation("common")(Media);
