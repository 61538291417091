import React, { Component } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
class Signform extends Component {
  constructor() {
    super()
    this.state = {
      fields: {},
      errors: {}
    }
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  // handleChange(e) {
  //   // *** validation for Firstname,Lastname,Contact,Email and Password ***

  //   let pattern = ""
  //   switch (e.target.name) {
  //     case 'firstname':                  // allows only Text
  //       pattern = /^[a-zA-Z]+$/;
  //       //pattern = /^\s*$/;             // checks for null
  //       break;

  //     case 'lastname':                  // allows only Text
  //       pattern = /^[a-zA-Z]+$/;
  //       break;

  //     case 'contact':                   // allows only Number
  //       pattern = /^\+[1-9]{1}[0-9]{11,11}$/;
  //       break;

  //     case 'email':                     // allows Email format
  //       pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //       break;

  //     case 'password':                 // allows min 8 character, special character, a uppercase and lowercase letter
  //       pattern = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})$/;
  //       break;

  //     default:
  //       break;
  //   }

  //   if (pattern.test(e.target.value)) {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       ['valid' + e.target.name]: 'true'
  //     })
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       ['valid' + e.target.name]: 'false'
  //     })
  //   }
  // }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["firstname"] = "";
      fields["lastname"] = "";
      fields["contact"] = "";
      fields["email"] = "";
      fields["password"] = "";
      this.setState({ fields: fields });
      alert("Form submitted");
    }

  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = 'false';
    }

    if (typeof fields["firstname"] !== "undefined") {
      if (!fields["firstname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["firstname"] = 'false';
      }
    }

    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = 'false';
    }

    if (typeof fields["lastname"] !== "undefined") {
      if (!fields["lastname"].match(/^[a-zA-Z ]+$/)) {
        formIsValid = false;
        errors["lastname"] = 'false';
      }
    }

    if (!fields["contact"]) {
      formIsValid = false;
      errors["contact"] = 'false';
    }

    if (typeof fields["contact"] !== "undefined") {
      if (!fields["contact"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["contact"] = 'false';
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = 'false';
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = 'false';
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = 'false';
    }

    if (typeof fields["password"] !== "undefined") {
      if (!fields["password"].match(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})$/)) {
        formIsValid = false;
        errors["password"] = 'false';
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  // handleSubmit = (e) => {
  //   //  e.target.checkValidity();

  //   const { firstname, lastname, contact, email, password, validfirstname, validlastname, validcontact, validemail, validpassword } = this.state

  //   if (firstname != '' && validfirstname != 'false') {
  //     if (lastname != '' && validlastname != 'false') {
  //       if (contact.length != 2 && validcontact != 'false') {
  //         if (email != '' && validemail != 'false') {
  //           if (password != '' && validpassword != 'false') {
  //             return
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     e.preventDefault();
  //     this.setState({
  //       validfirstname: 'false',
  //       validlastname: 'false',
  //       validcontact: 'false',
  //       validemail: 'false',
  //       validpassword: 'false',
  //     });
  //   }
  //   console.log("validfirstname:", validfirstname)
  //   console.log("validlastname:", validlastname)
  //   console.log("validcontact:", validcontact)
  //   console.log("validemail:", validemail)
  //   console.log("validPassword:", validpassword)
  //   // e.preventDefault();
  // };

  render() {
    return (
      <div className="bs-form login">
        <Form onSubmit={this.submituserRegistrationForm}>
          <Row>
            <Col md={12}>
              <Form.Control
                placeholder="First Name"
                name="firstname"
                value={this.state.fields.firstname}
                onChange={this.handleChange}
                isValid={this.state.errors.firstname === 'true'}
                isInvalid={this.state.errors.firstname === 'false'}
                autoComplete='off'
              />
            </Col>
            <Col md={12}>
              <Form.Control
                placeholder="Last Name"
                name="lastname"
                value={this.state.fields.lastname}
                onChange={this.handleChange}
                isValid={this.state.errors.lastname === 'true'}
                isInvalid={this.state.errors.lastname === 'false'}
                autoComplete='off'
              // required
              />
            </Col>
            <Col md={12}>
              <Form.Control
                type="text"
                placeholder="Contact No."
                name="contact"
                value={this.state.fields.contact}
                onChange={this.handleChange}
                isValid={this.state.errors.contact === 'true'}
                isInvalid={this.state.errors.contact === 'false'}
                autoComplete='off'
              // required
              />
            </Col>
            <Col md={12}>
              <Form.Control
                name="email"
                placeholder="EMAIL ID"
                value={this.state.fields.email}
                isValid={this.state.errors.email === 'true'}
                isInvalid={this.state.errors.email === 'false'}
                onChange={this.handleChange}
                autoComplete='off'
              // required
              />
              <Form.Control.Feedback type="valid" style={{ position: "absolute", right: 181, top: 40, fontSize: 13 }}>
                Perfect !!
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid" style={{ position: "absolute", right: 119, top: 40, fontSize: 13 }}>
                Please input a correct email.
              </Form.Control.Feedback>
              {/* <FormText>Your username is most likely your email.</FormText> */}

            </Col>
            <Col md={12}>
              <Form.Control
                type="password"
                name="password"
                id="examplePassword"
                placeholder="PASSWORD"
                value={this.state.fields.password}
                onChange={this.handleChange}
                isValid={this.state.errors.password === 'true'}
                isInvalid={this.state.errors.password === 'false'}
              // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              // required
              />
              <Row>
                <p style={{ position: "absolute", textAlign: "left", left: 15, top: 40, fontSize: 12, color: '#c8c8c8' }}>Must be 8 characters including one uppercase letter,<br></br>one special character and alphanumeric characters.</p>
              </Row>
            </Col>

            <Button type="submit" className="ctm-btn" >Sign Up</Button>
            {/* <Button type="submit" className="ctm-btn" disabled={!this.enableSubmit()} >Sign Up</Button> */}
          </Row>
        </Form>
      </div>
    )
  }
}
export default Signform;