const Notify = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('showAlert', { detail: obj });
    window.dispatchEvent(evt);
}

export const filterLanguage = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('filterLanguage', { detail: obj });
    window.dispatchEvent(evt);
}

export const changeVideoId = (obj)=>{
    var evt = new CustomEvent('changeVideoId', { detail: obj });
    window.dispatchEvent(evt);
}

export const changeTabFilter = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('changeTabFilter', { detail: obj });
    window.dispatchEvent(evt);
}

export const playVideo = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('playVideo', { detail: obj });
    window.dispatchEvent(evt);
}

export const searchTerm = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('searchTerm', { detail: obj });
    window.dispatchEvent(evt);
}

export const showSearch = (obj) => {
    // console.log("obj", obj);
    var evt = new CustomEvent('showSearch', { detail: obj });
    window.dispatchEvent(evt);
}
export const SidenavImgClick = (obj) =>{
    var evt = new CustomEvent('SidenavImgClick', { detail: obj });
    window.dispatchEvent(evt);
}

export default Notify;
