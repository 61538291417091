import React, { Component } from "react";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import Swiperseries from "./../module/Swiper-series";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { playVideo } from "../module/Notify";
import LazyLoad from "react-lazyload";
import playlistPlaceHolder from "../../images/playlist-placeholder.png";
import { Image as ShimmerImage } from "react-shimmer";
import Instagram from "../../common/Instagram";
var url = "";
var slug = "";
var id = "";
class Channel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      hideCarousel: false,
      isopen: true,
      type: "",
      Data: [],
      VideosData: null,
      ChannelData: [],
      lang: "en",
      loading: false,
      count: 0,
    };
  }

  toggle() {
    this.setState({
      addClass: !this.state.addClass,
      isopen: false,
    });
    playVideo(true);
  }
  //Change the lang using constructor
  changeLang = (val) => {
    const { i18n } = this.props;
    i18n.changeLanguage(val);
    this.getPlaylistData(val);
  };

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    if (this.state.scrollbar == true) {
      this.setState({
        scrollbar: false,
      });
    }
    var getLang = "";

    url = this.props.location.pathname;
    const url_array = url.split("/");
    id = url_array[1];
    slug = url_array[2];
    if (id == "video") {
      this.setState({
        addClass: !this.state.addClass,
        isopen: false,
        type: id,
        video_id: slug,
        count: 0,
      });
    } else if (id == "tag") {
      this.setState({
        loading: false,
      });
    }
    if (!window.localStorage.getItem("language") != null) {
      getLang = window.localStorage.getItem("language") || "en";
      this.changeLang(getLang);
      this.setState({
        lang: getLang,
      });
    }
    this.getLanguage();
    this.getPlaylistData(getLang);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.location.href = window.location.href;
      window.scroll(0, 0);
    }
    if (this.state.scrollbar == false) {
      this.setState({
        scrollbar: true,
      });
      window.scroll(0, 0);
    }
  }

  //Loader function
  imgLoader = (data) => {
    if (data) {
    }
    if (this.state.count >= 2) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        count: this.state.count + 1, //counts 'onLoad' in Img tags and update
      });
    }
  };

  getLanguage() {
    window.addEventListener("filterLanguage", (e) => {
      this.setState({
        lang: e.detail,
      });
      const { i18n } = this.props;
      i18n.changeLanguage(e.detail);
      this.getPlaylistData(e.detail);
    });
  }

  handleImageLoadError(e) {
    e.target.src = require("../../images/playlist-placeholder.png");
  }

  closeParentBanner = () => {
    this.setState({
      addClass: false,
      isopen: true,
      type: "playlist",
    });
  };
  getPlaylistData(lang) {
    var networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.get_series_page_data);
    networkHelper.setMethod("POST");
    networkHelper.setContentType("application/json");
    if (id === "playlist") {
      networkHelper.setData(
        JSON.stringify({
          lang_code: lang,
          playlist_id: slug,
        })
      );
    } else if (id === "video") {
      networkHelper.setData(
        JSON.stringify({
          lang_code: lang,
          video_id: slug,
        })
      );
    } else if (id == "tag") {
      networkHelper.setData(
        JSON.stringify({
          lang_code: lang,
          tag_name: slug,
        })
      );
    }

    networkHelper.execute(
      (response) => {
        this.setState({
          Data: response.data.data,
          ChannelData: response.data.data.channel,
          VideosData: response.data.data.videos,
        });
      },
      function (errorMsg) {
        console.log(errorMsg);
      },
      function () {}
    );
  }

  render() {
    const { Data, ChannelData, VideosData } = this.state;
    let anchorClass = ["mod-play-btn"];
    let backArrowClass = ["back-btn"];
    if (this.state.addClass) {
      anchorClass.push("wow fadeOut animated");
      backArrowClass.push("wow fadeOut animated");
    } else {
      anchorClass.push("wow fadeIn animated");
      backArrowClass.push("wow fadeIn animated");
    }

    return (
      <div>
        {VideosData != null ? (
          <div className="parent-mod">
            <section className="mod-swiper">
              <Container fluid={true} className="no-padding">
                {this.state.isopen == true ? (
                  <div>
                    <a
                      className={anchorClass.join(" ")}
                      onClick={this.toggle.bind(this)}
                    >
                      <i className="icon icon-play"></i>
                    </a>
                  </div>
                ) : null}

                <Swiperseries
                  closeBanner={() => {
                    this.closeParentBanner();
                  }}
                  videoData={VideosData}
                  playlistData={Data}
                  lang={this.state.lang}
                  pathname={id == "tag" ? slug : "not_tag"}
                  hideObject={this.state.addClass}
                  autoplay={
                    this.state.type == "video" ? this.state.video_id : null
                  }
                />
              </Container>
            </section>
          </div>
        ) : (
          <div className="parent-mod txt-center">
            <h1 style={{ color: "#FFFFFF" }}>Loading ...</h1>
          </div>
        )}

        <section className="lyt-white base no-space">
          <Container>
            {ChannelData != null ? (
              <Container>
                <Row>
                  <Col lg={12}>
                    <h2 className="title wow fadeInUp txt-center">
                      {ChannelData.name} Series
                    </h2>
                  </Col>
                </Row>
                <Row className="bx-parent">
                  {ChannelData.playlist != null ? (
                    Object.keys(ChannelData.playlist)
                      .reverse()
                      .map((id) => (
                        <Col
                          lg={3}
                          sm={6}
                          key={id}
                          className="bs-box wow fadeInUp"
                          data-wow-delay="0.1s"
                        >
                          <Link
                            to={`/playlist/${ChannelData.playlist[id].id}`}
                            className="ctm-block"
                          >
                            <figure>
                              <div className="ctm-hid">
                                <LazyLoad height={200}>
                                  <Image
                                    NativeImgProps={{
                                      className: "img-fluid pic",
                                    }}
                                    fallback={
                                      <Image
                                        src={playlistPlaceHolder}
                                        fluid
                                        className="pic"
                                      />
                                    }
                                    src={
                                      this.state.lang === "en"
                                        ? ChannelData.playlist[id].image.en
                                        : ChannelData.playlist[id].image.ma
                                    }
                                    height={300}
                                    width={250}
                                    onError={this.handleImageLoadError}
                                  />
                                </LazyLoad>
                              </div>
                              <figcaption className="head white">
                                {ChannelData.playlist[id].name}
                              </figcaption>
                            </figure>
                          </Link>
                        </Col>
                      ))
                  ) : (
                    <div></div>
                  )}
                </Row>
              </Container>
            ) : (
              <div></div>
            )}
          </Container>
        </section>

        <section className="lyt-social">
          <Container fluid={true}>
            <Row>
              <Col md={12} lg={12} className="bg-base">
                <h2
                  className="title wow fadeInUp footer-text"
                  data-wow-delay="0.1s"
                >
                  {this.props.t("social.watch", { framework: "Watch us on" })}
                </h2>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ"
                  data-wow-delay="0.2s"
                >
                  <figure>
                    <Image src={youtube} fluid />
                  </figure>
                </a>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/BhaDiPa/"
                  data-wow-delay="0.3s"
                >
                  <figure>
                    <Image src={facebook} fluid />
                  </figure>
                </a>
                <Instagram />
                <a
                  href="/contact"
                  className="cm-btn bg-shadow wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  {this.props.t("social.get", { framework: "Get In Touch" })}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(withRouter(Channel));
