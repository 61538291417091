import React from "react";
import { withRouter } from "react-router";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import SearchSwiper from "../module/SearchSwiper";

var term = "";
class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: "en",
			SearchData: null,
			PlaylistData: null,
			VideoData: null,
			ChannelData: null,
			term: "aai",
		};
	}

	UNSAFE_componentWillMount() {
		window.addEventListener("searchTerm", (e) => {
			if (e.detail !== null && e.detail !== "") {
				// console.log("Ab", e.detail)
				term = e.detail;
				this.setState({
					SearchData: null,
					ChannelData: null,
					PlaylistData: null,
					VideoData: null,
				});
				this.fetchSearchPageData();
			}
		});
		if (window.localStorage.getItem("language") !== null) {
			const getLang = window.localStorage.getItem("language") || "en";
			this.changeLang(getLang);
			this.setState({
				lang: getLang,
			});
		}
		if (window.localStorage.getItem("search") !== null) {
			term = window.localStorage.getItem("search") || "aai";
			this.fetchSearchPageData();
		}
		this.getLanguage();
	}

	fetchSearchPageData = (lang) => {
		if (term.length >= 3) {
			var networkHelper = new NetworkHelper();
			networkHelper.setUrl(Constants.get_search_page_data);
			networkHelper.setMethod("POST");
			networkHelper.setData(
				JSON.stringify({
					lang_code: lang,
					term: term,
				})
			);
			networkHelper.execute(
				(response) => {
					// console.log("Home Page Data >>>", response.data)
					if (response.data.data !== null) {
						this.setState({
							SearchData: response.data.data,
							ChannelData: response.data.data.channel,
							PlaylistData: response.data.data.playlist,
							VideoData: response.data.data.video,
						});
					}
				},
				function (errorMsg, StatusCode) {
					console.log(errorMsg, StatusCode);
				},
				function () {}
			);
		}
	};
	// Event emiter for language changed
	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			const { i18n } = this.props;
			this.setState({
				lang: e.detail,
			});
			i18n.changeLanguage(e.detail);
			this.fetchSearchPageData(e.detail);
		});
	}

	//Change the lang using constructor
	changeLang = (val) => {
		const { i18n } = this.props;
		i18n.changeLanguage(val);
		this.fetchSearchPageData(val);
	};

	render() {
		const { SearchData, ChannelData, PlaylistData, VideoData } = this.state;
		return (
			<div>
				{term.length < 3 ? (
					<Container>
						<Row>
							<Col className="txt-center" md={12}>
								<h1>Enter the keyword to Search</h1>
								<p>(Upto 3 letters)</p>
							</Col>
						</Row>
					</Container>
				) : null}
				{SearchData == null && term.length >= 3 ? (
					<Container>
						<Row>
							<Col className="txt-center" md={12}>
								<h1>No Results Found</h1>
								<p>(Search another keyword)</p>
							</Col>
						</Row>
					</Container>
				) : null}
				{/* Channel Data */}
				{ChannelData !== null && ChannelData !== "undefined" && SearchData !== null ? <SearchSwiper class="lyt-swiper theme" swip="right" data={ChannelData} lang={this.state.lang} type="1" /> : null}

				{/* Playlist Data */}

				{PlaylistData !== null && PlaylistData !== "undefined" && SearchData !== null ? <SearchSwiper class="lyt-swiper white" swip="left" data={PlaylistData} lang={this.state.lang} type="2" /> : null}

				{/* Video Data */}
				{VideoData !== null && VideoData !== "undefined" && SearchData !== null ? <SearchSwiper class="lyt-swiper base" swip="right" data={VideoData} lang={this.state.lang} type="3" /> : null}
			</div>
		);
	}
}

export default withTranslation("common")(withRouter(Search));
