import React from "react";
import Swiper from "react-id-swiper";
import { Image } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Shimmer } from 'react-shimmer'
import videoPlaceHolder from "../../images/video-placeholder.png";
const MutipleSlidesPerView = (props) => {
	const Data = props.recents;
	const lang = props.lang;
	const params = {
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: {
			nextEl: ".base .channel-desc .ctm-right",
			prevEl: ".base .channel-desc .ctm-left",
		},
		//   autoplay: {
		//     delay: 3000,
		//     disableOnInteraction: false
		//   },
		breakpoints: {
			1024: {
				slidesPerView: 4,
				spaceBetween: 20,
				navigation: {
					nextEl: ".base .channel-desc .ctm-right",
					prevEl: ".base .channel-desc .ctm-left",
				},
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 20,
				navigation: {
					nextEl: ".base.channel-desc.ctm-right",
					prevEl: ".base.channel-desc.ctm-left",
				},
			},
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
				navigation: {
					nextEl: ".base.channel-desc.ctm-right",
					prevEl: ".base.channel-desc.ctm-left",
				},
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
				navigation: {
					nextEl: ".base.channel-desc.ctm-right",
					prevEl: ".base.channel-desc.ctm-left",
				},
			},
		},
	};

	return (
		<Swiper {...params} shouldSwiperUpdate>
			{Data != null
				? Object.keys(Data).map((id, index) => (
						<div key={index}>
							<a href={`https://bhadipa.com/video/${Data[id]._id}`} className="ctm-block">
								<figure className="series-pic">
									<LazyLoad height={200}>
										<Image fallback={<Image src={videoPlaceHolder} height="260" width="100%" />} NativeImgProps={{ height: 260, width: "100%" }} src={lang === "en" ? (Data[id].banner.en != null ? Data[id].banner.en : `https://bhadipa.com/beta/admin/public/api/get_default_image/video`) : Data[id].banner.ma != null ? Data[id].banner.ma : `https://bhadipa.com/beta/admin/public/api/get_default_image/video`} />
									</LazyLoad>
									<div className="mod-play-btn">
										<i className="icon icon-play"></i>
									</div>
								</figure>
								<figcaption className="video-name">{Data[id].video_title}</figcaption>
							</a>
						</div>
				  ))
				: null}
		</Swiper>
	);
};

export default MutipleSlidesPerView;
