import React from "react";

import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import WOW from "wow.js";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import { changeTabFilter } from "../module/Notify";
import LazyLoad from "react-lazyload";
import { HashLink } from "react-router-hash-link";
import videoPlaceHolder from "../../images/video-placeholder.png";
import {Image as ShimmerImage } from "react-shimmer";
class Eventformats extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			EventsFormatData: [],
			loading: false,
			count: 0,
			lang: "en",
		};
		this.changeTab = this.changeTab.bind(this);
	}

	changeTab = (value) => {
		changeTabFilter(value);
		window.localStorage.setItem("tab", value);
	};

	//Change the lang using constructor
	changeLang = (val) => {
		const { i18n } = this.props;
		i18n.changeLanguage(val);
		this.fetchEventsFormatData(val);
	};
	UNSAFE_componentWillMount() {
		const wow = new WOW().init();
		if (!window.localStorage.getItem("language") !== null) {
			const getLang = window.localStorage.getItem("language") || "en";
			this.changeLang(getLang);
			this.setState({
				lang: getLang,
			});
		}

		this.getLanguage();
	}

	//Loader function
	imgLoader = () => {
		if (this.state.count >= 2) {
			this.setState({
				loading: false,
			});
		} else {
			this.setState({
				count: this.state.count + 1, //counts 'onLoad' in Img tags and update
			});
		}
	};

	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			const { t, i18n } = this.props;
			i18n.changeLanguage(e.detail);
			this.fetchEventsFormatData(e.detail);
		});
	}

	fetchEventsFormatData = (lang) => {
		var networkHelper = new NetworkHelper();
		networkHelper.setUrl(Constants.get_events_page_data);
		networkHelper.setMethod("POST");
		networkHelper.setData(
			JSON.stringify({
				lang_code: lang,
			})
		);
		networkHelper.execute(
			(response) => {
				this.setState({
					EventsFormatData: response.data.events,
				});
			},
			function (errorMsg, StatusCode) {
				console.log(errorMsg);
			},
			function () {}
		);
	};

	convertToSlug = (string) => {
		return string
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	};

	render() {
		const { EventsFormatData } = this.state;
		return (
			<div>
				<section className="lyt-white">
					{/* <div>
						<div className="container">
							<a href="#" className="back-btn typ-eventformat">
								<i className="icon icon-right-arrow-big"></i>Back
							</a>
						</div>
					</div> */}
					<Container>
						{EventsFormatData !== null
							? Object.keys(EventsFormatData).map((id) => (
									<Row className="mod-format" id={`${this.convertToSlug(EventsFormatData[id].title)}`}>
										<Col lg={12}></Col>
										<Col lg={4}>
											<a href={`#${this.convertToSlug(EventsFormatData[id].title)}`} className="ctm-block wow fadeInLeft">
												<figure>
													<div className="ctm-hid">
														<LazyLoad height={200}>
															<ShimmerImage fallback={<Image src={videoPlaceHolder} fluid className="pic" />} NativeImgProps={{ className: "img-fluid pic" }} src={EventsFormatData[id].banner.ma} />
														</LazyLoad>
													</div>
												</figure>
											</a>
										</Col>
										<Col lg={8} className="info">
											<div className="description">
												<h3 className="name wow fadeInUp" data-wow-delay="0.1s">
													{EventsFormatData[id].title}
												</h3>
												<p className="desp wow fadeInUp" data-wow-delay="0.2s">
													{EventsFormatData[id].description}
												</p>
											</div>
											<div className="artist">
												{EventsFormatData[id].comedians !== undefined ? (
													<Row>
														<Col md={12}>
															<h4 className="comedians wow fadeInUp" data-wow-delay="0.3s">
																{this.props.t("events_format.comedians", { framework: "Comedians" })}
															</h4>
														</Col>
														<Col md={12}>
															<div className="pictures" style={EventsFormatData[id].comedians.length > 4 ? { display: "flex" } : { display: "block" }}>
																{EventsFormatData[id].comedians !== null
																	? Object.keys(EventsFormatData[id].comedians).map((cid) => (
																			<HashLink to={`/comedians#${this.convertToSlug(EventsFormatData[id].comedians[cid].name)}`} className="ctm-block">
																				<figure className="wow fadeInUp" data-wow-delay="0.4s">
																					<div className="ctm-hid">
																						<LazyLoad height={200}>
																							<ShimmerImage fallback={<Image src={videoPlaceHolder} height="130" width="130" fluid className="pic" />} NativeImgProps={{ className: "img-fluid pic", height: 130, width: 130 }} src={EventsFormatData[id].comedians[cid].image.en} />
																						</LazyLoad>
																					</div>
																					<figcaption className="name">{EventsFormatData[id].comedians[cid].name}</figcaption>
																				</figure>
																			</HashLink>
																	  ))
																	: null}
															</div>
														</Col>
													</Row>
												) : null}
												{EventsFormatData[id].performers !== undefined ? (
													<Row>
														<Col md={12}>
															<h4 className="comedians wow fadeInUp" data-wow-delay="0.3s">
																{this.props.t("events_format.performers", { framework: "Performers" })}
															</h4>
														</Col>
														<Col md={12}>
															<div className="pictures" style={EventsFormatData[id].performers.length > 4 ? { display: "flex" } : { display: "block" }}>
																{EventsFormatData[id].performers !== null
																	? Object.keys(EventsFormatData[id].performers).map((pid) => (
																			<a href={EventsFormatData[id].performers[pid].name == "Amey Wagh" ? `https://www.instagram.com/ameyzone/?igshid=1mc0o3sm86v0` : EventsFormatData[id].performers[pid].name == "Nipun Dharmadhikari" ? `https://www.instagram.com/nipundharmadhikari/?igshid=1cosu9dgpqyqa` : ""} target={EventsFormatData[id].performers[pid].name == "Amey Wagh" || EventsFormatData[id].performers[pid].name == "Nipun Dharmadhikari" ? "_blank" : ""} className="ctm-block">
																				<figure className="wow fadeInUp" data-wow-delay="0.4s">
																					<div className="ctm-hid">
																						<LazyLoad height={200}>
																							<ShimmerImage fallback={<Image src={videoPlaceHolder} height="130" width="130" fluid className="pic" />} NativeImgProps={{ className: "img-fluid pic", height: 130, width: 130 }} src={EventsFormatData[id].performers[pid].image} />
																						</LazyLoad>
																					</div>
																					<figcaption className="name">{EventsFormatData[id].performers[pid].name}</figcaption>
																				</figure>
																			</a>
																	  ))
																	: null}
															</div>
														</Col>
													</Row>
												) : null}
											</div>
											<Link to="/contact" onClick={() => this.changeTab("collaborate")} className="cm-btn yellow bg-shadow wow fadeInUp" data-wow-delay="0.2s">
												{" "}
												{this.props.t("events_format.enquiry", { framework: "Enquire Now" })}{" "}
											</Link>
										</Col>
									</Row>
							  ))
							: null}

						<Row>
							<Col lg={12} className="txt-center wow fadeInUp">
								<Link to="/contact" onClick={() => this.changeTab("collaborate")} className="cm-btn yellow bg-shadow">
									{this.props.t("events_format.book", { framework: "Book corporate event" })}
								</Link>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default withTranslation("common")(Eventformats);
