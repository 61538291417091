import React, { Component } from "react";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";

import Swiperrecentchannel from "./../module/Swiper-recent-channel";
import { Container, Row, Col, Image } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import LazyLoad from "react-lazyload";
import loaderImg from "../../images/loader.gif";
import { Image as ShimmerImage } from "react-shimmer";
import playlistPlaceHolder from "../../images/playlist-placeholder.png";
import Instagram from "../../common/Instagram";
var url = "";
var slug = "";
var id = "";
class Channel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ChannelData: null,
      recentsData: [],
      subCategory: [],
      featured_video: [],
      lang: "en",
      loading: false,
      count: 0,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.location.reload(true);
    }
  }
  UNSAFE_componentWillMount() {
    window.scroll(0, 0);
    this.getLanguage();
    const getLang = window.localStorage.getItem("language") || "en";
    this.setState({
      lang: getLang,
    });
    url = this.props.location.pathname;
    const url_array = url.split("/");
    id = url_array[1];
    slug = url_array[2];
    this.fetchChannelPageData(getLang);
  }

  //Loader function
  imgLoader = () => {
    let imgCount = 4;
    if (this.state.count >= imgCount) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        count: this.state.count + 1,
      });
    }
  };

  getLanguage() {
    window.addEventListener("filterLanguage", (e) => {
      this.setState({
        lang: e.detail,
      });
      // console.log("PROPS", this.props)
      const { i18n } = this.props;
      i18n.changeLanguage(e.detail);
      this.fetchChannelPageData(e.detail);
    });
  }

  handleImageLoadError(e) {
    e.target.src = require("../../images/playlist-placeholder.png");
  }

  fetchChannelPageData = (lang) => {
    var networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.get_channel_page_data);
    networkHelper.setMethod("POST");
    networkHelper.setData(
      JSON.stringify({
        _id: slug,
        lang_code: lang,
      })
    );
    networkHelper.execute(
      (response) => {
        // Process the response and check image URLs for validity
        // const subCategoryData = response.data.data.subcategory.map((subcategory) => {
        // 	const imageUrlEn = subcategory.banner.en;
        // 	const imageUrlMa = subcategory.banner.ma;

        // 	// Function to check if an image URL is valid
        // 	const isValidImageUrl = (url) => {
        // 	  const img = new Image();
        // 	  img.src = url;
        // 	  return img.complete && img.naturalWidth !== 0;
        // 	};

        // 	// Check and update the image URLs if they are invalid
        // 	if (imageUrlEn && !isValidImageUrl(imageUrlEn)) {
        // 	  subcategory.banner.en = playlistPlaceHolder; // Replace with the fallback image
        // 	}
        // 	if (imageUrlMa && !isValidImageUrl(imageUrlMa)) {
        // 	  subcategory.banner.ma = playlistPlaceHolder; // Replace with the fallback image
        // 	}

        // 	return subcategory;
        //   });

        this.setState({
          ChannelData: response.data.data.channel,
          recentsData: response.data.data.recently_added,
          subCategory: response.data.data.subcategory,
          featured_video: response.data.data.featured_video,
        });
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg);
      },
      function () {}
    );
  };

  render() {
    const { recentsData, subCategory, ChannelData } = this.state;
    return (
      <div className="channel-page">
        {/* loader-spinner */}
        {this.state.loading === true ? (
          <div className="loader-overlay">
            <img src={loaderImg} alt="loader" />
          </div>
        ) : null}
        {ChannelData != null ? (
          <div className="channel-series hide-play">
            {/* Banner Part */}

            <section
              style={{
                backgroundImage:
                  this.state.lang === "en"
                    ? `url(${ChannelData.desktop_banner.en})`
                    : `url(${ChannelData.desktop_banner.ma})`,
              }}
              className="mod-bg"
            >
              <Link
                className="mod-play-btn"
                to={`/video/${ChannelData.feature_video_id}`}
              >
                <i className="icon icon-play"></i>
              </Link>
              <figure>
                {/* <LazyLoad height={200}> */}
                <Image
                  src={
                    this.state.lang === "en"
                      ? ChannelData.desktop_banner.en
                      : ChannelData.desktop_banner.ma
                  }
                  fluid
                  className="mob-pic"
                />
                {/* </LazyLoad> */}
              </figure>
            </section>

            {/* Channel Logo & Description */}
            <section className="lyt-yellow">
              <Container fluid={true}>
                <Row>
                  <Col lg={{ span: 10, offset: 2 }} className="bg-theme">
                    <figure className="wow fadeInLeft">
                      {/* <LazyLoad height={200}> */}
                      <Image
                        src={
                          this.state.lang === "en"
                            ? ChannelData.logo.en
                            : ChannelData.logo.ma
                        }
                        height="180"
                        width="180"
                        fluid
                      />
                      {/* </LazyLoad> */}
                    </figure>
                    <p className="wow fadeInUp">
                      {ChannelData.channel_description}
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>

            {/* Channel name */}
            <section className="lyt-white">
              <Container>
                <Row>
                  <Col lg={12}>
                    <h2 className="title wow fadeInUp txt-center">
                      {ChannelData.channel_title}{" "}
                      {this.props.t("channel.series")}
                    </h2>
                  </Col>
                </Row>

                {/* Playlists */}
                <Row>
                  {subCategory !== null
                    ? Object.keys(subCategory)
                        .reverse()
                        .map((id, index) => {
                          const playlist = subCategory[id];
                          const imageUrl =
                            this.state.lang === "en"
                              ? playlist.banner.en
                              : playlist.banner.ma;
                          const hasImage = imageUrl && imageUrl.trim() !== ""; // Check if there's a valid image URL

                          return (
                            <Col
                              key={index}
                              lg={3}
                              sm={6}
                              className="bs-box wow fadeInUp"
                              data-wow-delay="0.1s"
                            >
                              <Link
                                to={`/playlist/${subCategory[id]._id}`}
                                className="ctm-block"
                              >
                                <figure>
                                  <div className="ctm-hid">
                                    <LazyLoad
                                      overflow
                                      scrollContainer={true}
                                      scroll={true}
                                      height={200}
                                    >
                                      {hasImage ? (
                                        <Image
                                          NativeImgProps={{
                                            className: "img-fluid pic",
                                          }}
                                          fallback={
                                            <Image
                                              src={require("../../images/playlist-placeholder.png")}
                                              fluid
                                              height={270}
                                              width={255}
                                              className="pic"
                                            />
                                          }
                                          src={imageUrl}
                                          height={300}
                                          width={250}
                                          onError={this.handleImageLoadError}
                                        />
                                      ) : (
                                        <Image
                                          src={require("../../images/playlist-placeholder.png")}
                                          fluid
                                          className="pic"
                                        />
                                      )}
                                    </LazyLoad>
                                  </div>
                                  <figcaption className="head base">
                                    {subCategory[id].name}
                                  </figcaption>
                                </figure>
                              </Link>
                            </Col>
                          );
                        })
                    : null}
                </Row>
              </Container>
            </section>
          </div>
        ) : null}
        {/* Recently Added */}
        <section className="lyt-swiper base recent">
          <Container>
            <Row>
              <Col md={12} className="wow fadeInUp">
                <h2>
                  {this.props.t("channel.recents", {
                    framework: "Recently Added",
                  })}
                </h2>
              </Col>
              <Col md={12} className="wow fadeInUp" data-wow-delay="0.2s">
                <Swiperrecentchannel
                  recents={recentsData}
                  lang={this.state.lang}
                />
              </Col>
              <Col md={12} className="channel-desc">
                <div className="ctm-left"></div>
                <div className="ctm-right"></div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Social */}
        <section className="lyt-social">
          <Container fluid={true}>
            <Row>
              <Col md={12} lg={12} className="bg-base">
                <h2
                  className="title wow fadeInUp footer-text"
                  data-wow-delay="0.1s"
                >
                  {this.props.t("social.watch", { framework: "Watch us on" })}
                </h2>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ"
                  data-wow-delay="0.2s"
                >
                  <figure>
                    <LazyLoad height={200}>
                      <Image src={youtube} fluid />
                    </LazyLoad>
                  </figure>
                </a>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/BhaDiPa/"
                  data-wow-delay="0.3s"
                >
                  <figure>
                    <LazyLoad height={200}>
                      <Image src={facebook} fluid />
                    </LazyLoad>
                  </figure>
                </a>
                <Instagram />
                <a
                  href="/contact"
                  className="cm-btn bg-shadow wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  {this.props.t("social.get", { framework: "Get In Touch" })}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(withRouter(Channel));
