import React from "react";
// import Swiper from 'react-id-swiper';

import { Image, Row, Col } from "react-bootstrap";
const Swiper = React.lazy(() => import("react-id-swiper"));
const SwiperSection = (props) => {
	let sectionData = props.data;

	const params = {
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	};

	const handleImageClick = (url) => {
		if (typeof url !== "undefined") {
			window.location.href = url;
			return false;
		}
	};

	return (
		<React.Suspense fallback={<div></div>}>
			<Swiper {...params} shouldSwiperUpdate>
				{sectionData != null ? (
					// console.log("Section 1 Data >>", sectionData)
					Object.keys(sectionData).map((id) => (
						// console.log("Heelo", sectionData[id].category)
						<div className="bs-swiper bg-color">
							<Row>
								<h2>{sectionData[id].category}</h2>
								<Col xs={12} md={5}>
									<figure className="slide-pic">
										{/* <Image src={home3} fluid /> */}
										{/* <LazyLoad height={200}> */}
										<Image
											src={sectionData[id].banner.en}
											fluid
											onClick={() => {
												handleImageClick(`video/${sectionData[id].video.id}`);
											}}
											style={{ cursor: "pointer" }}
										/>
										{/* </LazyLoad> */}
									</figure>
								</Col>
								<Col xs={12} md={7}>
									<div className="slide-txt">
										<div className="video-name cursor-point">
											{sectionData[id].video.title.length > 40 ? (
												<p>
													<a href={`video/${sectionData[id].video.id}`}>{`${sectionData[id].video.title.substring(0, 40)}...`}</a>
												</p>
											) : (
												<p>
													<a href={`video/${sectionData[id].video.id}`}>{sectionData[id].video.title}</a>
												</p>
											)}
										</div>
										<div className="channel-name">{sectionData[id].channel.title}</div>
									</div>
									<a className="mod-play-btn" href={`video/${sectionData[id].video.id}`}>
										<i className="icon icon-play"></i>
									</a>
								</Col>
							</Row>
						</div>
					))
				) : (
					<div></div>
				)}
			</Swiper>
		</React.Suspense>
	);
};

export default SwiperSection;
