import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
const VideoDetail = (props) => {
	const video = props.video;
	const [desc, setDesc] = useState(true);
	const [popupHeading, setPopupHeading] = useState("");
	const [popupDescription, setPopupDescription] = useState("");
	const [popup, setPopup] = useState(false);

	// console.log("VideoDetail",props.video)
	if (!video) {
		return <div>Loading...</div>;
	}

	const handlePopup = (title, description) => {
		// console.log(title);
		if (popup === false) {
			setPopup(true);
			setPopupDescription(description);
			setPopupHeading(title);
		} else {
			setPopup(false);
		}
	};
	// const videoId = video.id.videoId;
	const videoId = video.url;
	var url = "";
	if (props.autoplay != null) {
		url = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
	} else {
		url = `https://www.youtube.com/embed/${videoId}`;
	}

	return (
		<div className="video-detail">
			<div className="embed-responsive embed-responsive-16by9">
				<iframe className="embed-responsive-item" src={url} allow="autoplay"></iframe>
			</div>
			<div className="details series-video-dtls">
				{/* <h3>{video.title.length > 40 ? `${video.title.substring(0, 40)}...` : video.title}</h3> */}
				<h3>{video.title}</h3>
				{video.description != null ? (
					video.description.length > 100 ? (
						<p className="mob-info">
							<div
								dangerouslySetInnerHTML={{
									__html: desc == true ? `${video.description}` : video.description,
								}}
							></div>
							<Button className="model-btn" variant="secondary" onClick={() => handlePopup(video.title, video.description)}>
								{props.t("video_details.read_more", { framework: "Read More" })}
							</Button>
						</p>
					) : (
						<p>{video.description}</p>
					)
				) : null}
				{/* <p>{video.description}</p> */}
			</div>
			<Modal
				show={popup}
				onHide={() => {
					handlePopup();
				}}
				className="bs-popup"
			>
				<Modal.Header closeButton>
					<Modal.Title>{popupHeading}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						dangerouslySetInnerHTML={{
							__html: popupDescription,
						}}
					></div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="model-btn"
						variant="secondary"
						onClick={() => {
							handlePopup();
						}}
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default withTranslation("common")(VideoDetail);
