import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { Image as ShimmerImage } from "react-shimmer";
import playlistPlaceHolder from "../../images/playlist-placeholder.png";
var swip = "";
var data = [];
var lang = "en";
var nav = {};
const Swiper = React.lazy(() => import("react-id-swiper"));
const SwiperComponent = (props) => {
  console.log(props);
  let key = props.type;
  if (key === "1") {
    nav = {
      nextEl: ".theme .channel-desc .ctm-right",
      prevEl: ".theme .channel-desc .ctm-left",
    };
  } else if (key === "2") {
    nav = {
      nextEl: ".white .channel-desc .ctm-right",
      prevEl: ".white .channel-desc .ctm-left",
    };
  } else if (key === "3") {
    nav = {
      nextEl: ".base .channel-desc .ctm-right",
      prevEl: ".base .channel-desc .ctm-left",
    };
  }
  const params = {
    slidesPerView: 3,
    spaceBetween: 20,
    navigation: nav,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false
    // },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: nav,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: nav,
      },
    },
  };
  data = props.channelData;
  swip = props.swip;
  lang = props.lang;

  useEffect(() => {});

  const handleImageLoadError = (e) => {
    // If the main image fails to load, switch to the fallback image
    e.target.src = require("../../images/playlist-placeholder.png");
  };

  return (
    <section className={props.class}>
      <Container>
        {data != null ? (
          <Row>
            <Col
              md={swip === "left" ? { span: 4, order: 12 } : 4}
              className="channel-desc wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <h2>
                {data.channel.name}{" "}
                {props.t("home.series", { framework: "Series" })}
              </h2>
              <p>{data.channel.description}</p>

              <div style={{ cursor: "pointer" }} className="ctm-left"></div>
              <div style={{ cursor: "pointer" }} className="ctm-right"></div>
            </Col>
            <Col
              md={swip === "left" ? { span: 8, order: 1 } : 8}
              className="wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <React.Suspense fallback={<div></div>}>
                <Swiper {...params} shouldSwiperUpdate>
                  {Object.keys(data.playlist)
                    .reverse() // Reverse the order of keys
                    .map((id) => {
                      // const playlist = data.playlist[id];
                      // const imageUrl = this.state.lang === "en" ? playlist.banner.en : playlist.banner.ma;
                      // const hasImage = imageUrl && imageUrl.trim() !== ''; // Check if there's a valid image URL

                      const imageUrl =
                        lang === "en"
                          ? data.playlist[id].banner.en
                          : data.playlist[id].banner.ma;

                      // Function to check if the image URL is valid
                      const isValidImageUrl = (url) => {
                        // You can implement your own validation logic here
                        // For a simple check, you can use a regex pattern like this:
                        const urlPattern =
                          /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                        return urlPattern.test(url);
                      };

                      return (
                        <div key={id}>
                          <Link
                            to={`/playlist/${data.playlist[id].id}`}
                            className="ctm-block"
                          >
                            <figure className="series-pic">
                              <div className="ctm-hid">
                                <figure
                                  className="wow fadeInUp"
                                  data-wow-delay="0.1s"
                                >
                                  <LazyLoad
                                    overflow
                                    scrollContainer={true}
                                    scroll={true}
                                    height={200}
                                  >
                                    <Image
                                      NativeImgProps={{
                                        className: "img-fluid",
                                      }}
                                      fallback={
                                        <Image
                                          src={playlistPlaceHolder}
                                          height={270}
                                          width={255}
                                          fluid
                                        />
                                      }
                                      src={
                                        lang === "en"
                                          ? data.playlist[id].banner.en
                                          : data.playlist[id].banner.ma
                                      }
                                      onError={handleImageLoadError}
                                    />

                                    {/* {hasImage ? (
																		
																		<ShimmerImage NativeImgProps={{ className: "img-fluid" }} fallback={<Image src={playlistPlaceHolder} height={270} width={255} fluid />} src={imageUrl} />
																			
																		
																	) : (
																		<Image src={playlistPlaceHolder} fluid className="pic" />
																	)} */}

                                    {/* {isValidImageUrl(imageUrl) ? (
																				<Image NativeImgProps={{ className: "img-fluid" }} src={imageUrl} />
																				) : (
																				<Image src={playlistPlaceHolder} height={270} width={255} fluid />
																				)} */}
                                    {/* <img
																src={imageUrl}
																alt={`Image for ${data.playlist[id].name}`}
																onError={handleImageLoadError}
																/> */}
                                  </LazyLoad>
                                </figure>
                              </div>
                              <figcaption className="video-name">
                                {data.playlist[id].name}
                              </figcaption>
                            </figure>
                          </Link>
                        </div>
                      );
                    })}
                </Swiper>
              </React.Suspense>
            </Col>
          </Row>
        ) : null}
      </Container>
    </section>
  );
};

export default withTranslation("common")(SwiperComponent);
