import React from 'react';
import LazyLoad from 'react-lazyload'; // Assuming you're using a LazyLoad library
import { Image } from 'react-bootstrap'; // Assuming you're using React Bootstrap
import instagram from "../images/instagram-logo.png"
const Instagram = () => {
  return (
    <a
      className="fluid-icon last wow fadeInUp"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/bhadipa/"
      data-wow-delay="0.4s"
    >
      <figure>
        <LazyLoad height={200}>
          <Image src={instagram} fluid />
        </LazyLoad>
      </figure>
    </a>
  );
};

export default Instagram;
