import React, { Component } from "react";
import Sarang from "../../images/our-team/sarang.jpg";
import Anusha from "../../images/our-team/anusha.jpg";
import Paula from "../../images/our-team/paula.jpg";
import Aditya from "../../images/our-team/aditya.jpg";
import Abheeraj from "../../images/our-team/abheeraj-shinde.JPG";
import Neel from "../../images/our-team/neel-salekar.jpg";
import Somesh from "../../images/our-team/somesh-sahane.jpg";
import Shilpa from "../../images/our-team/shilpa-shetye.jpg";
import Swanand from "../../images/our-team/swanand-tendulkar.jpg";
import Chinmay from "../../images/our-team/chinmay-jadhav.jpeg";
import Arnob from "../../images/our-team/Arnob.jpg";
import Rohit from "../../images/our-team/sanika-pawar.JPG";
import Yash from "../../images/our-team/yash-shelar.jpg";
// import Sujit from "../../images/our-team/Sujit.jpg";
import AniketJadhav from "../../images/our-team/Aniket-Jadhav.jpg";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import Instagram from "../../common/Instagram";


class About extends Component {
	constructor() {
		super();
		this.state = {
			showText: false,
			loading: false,
			count: 0,
		};
		this.changeLang = this.changeLang.bind(this);
	}

	//Change the lang using constructor
	changeLang(val) {
		console.log("Changes", val);
		const { i18n } = this.props;
		i18n.changeLanguage(val);
	}

	UNSAFE_componentWillMount() {
		window.scroll(0, 0);
		if (!window.localStorage.getItem("language") != null) {
			const getLang = window.localStorage.getItem("language") || "en";
			this.changeLang(getLang);
		}
		this.getLanguage();
	}

	//Loader function
	imgLoader = () => {
		if (this.state.count >= 9) {
			this.setState({
				loading: false,
			});
			// console.log('nik', this.state.loading)
		} else {
			this.setState({
				count: this.state.count + 1, //counts 'onLoad' in Img tags and update
			});
		}
		console.log("img count", this.state.count);
	};

	// Event emiter for language changed
	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			const { i18n } = this.props;
			i18n.changeLanguage(e.detail);
		});
	}
	render() {
		return (
			<div>
				{/* loader-spinner */}
				{this.state.loading == true ? (
					<div class="section-blur">
						<div class="loader"></div>
					</div>
				) : null}
				<section className="mod-team">
					<Container className="clearfix">
						<div className="core-team clearfix">
							<div className="member wow fadeInUp" data-wow-delay="0.1s">
								<figure>
									<div className="ctm-hid">
										<LazyLoad height={200}>
											<Image onLoad={this.imgLoader} src={Sarang} fluid className="pic" />
										</LazyLoad>
									</div>
									<figcaption className="info">
										<h3 className="name">{this.props.t("about.person1.name", { framework: "Sarang Sathaye" })}</h3>
										<p className="tokan">{this.props.t("about.person1.description", { framework: "Token Maharashtrian" })}</p>
										<p className="designation">{this.props.t("about.person1.designation", { framework: "Creative Director, Writer-Director, Bearded" })}</p>
									</figcaption>
								</figure>
							</div>
							<div className="member wow fadeInUp" data-wow-delay="0.2s">
								<figure>
									<div className="ctm-hid">
										<LazyLoad height={200}>
											<Image onLoad={this.imgLoader} src={Anusha} fluid className="pic" />
										</LazyLoad>
									</div>
									<figcaption className="info">
										<h3 className="name">{this.props.t("about.person2.name", { framework: "Anusha Nanadakumar" })}</h3>
										<p className="tokan">{this.props.t("about.person2.description", { framework: "Token Tamilian" })}</p>
										<p className="designation">{this.props.t("about.person2.designation", { framework: "Long-Format Developer, Director-Producer, Subtitle-checker" })}</p>
									</figcaption>
								</figure>
							</div>
							<div className="member wow fadeInUp" data-wow-delay="0.3s">
								<figure>
									<div className="ctm-hid">
										<LazyLoad height={200}>
											<Image onLoad={this.imgLoader} src={Paula} fluid className="pic" />
										</LazyLoad>
									</div>
									<figcaption className="info">
										<h3 className="name">{this.props.t("about.person3.name", { framework: "Paula McGlynn" })}</h3>
										<p className="tokan">{this.props.t("about.person3.description", { framework: "Token Foreigner" })}</p>
										<p className="designation">{this.props.t("about.person3.designation", { framework: "CEO, Creative Producer, e-mail reader" })}</p>
									</figcaption>
								</figure>
							</div>
						</div>
					</Container>
				</section>
				<section className="lyt-white">
					<Container>
						<Row>
							<Col lg={8}>
								<h2 className="title wow fadeInUp">{this.props.t("about.mission_title", { framework: "About us" })}</h2>
								<p className="cm-para wow fadeInUp" data-wow-delay="0.1s">
									{this.props.t("about.mission_desc", { framework: "Good stories can cross borders and language-barriers. We want to take local content global by making it relevant and relatable, with international quality standards." })}
								</p>
								{/* <p className="cm-para wow fadeInUp" data-wow-delay="0.2s">{this.props.t('about.description2', { framework: "Bharatiya Digital Party (BhaDiPa) - We are completely non-political, undemocratic &amp; politically incorrect, so it's a Party! BhaDiPa is a collective of creators on the cutting-edge of Marathi language content. We make fresh, original, and global-minded stories and live events." })}</p>
                                <p className="wow fadeInUp" data-wow-delay="0.3s">{this.props.t('about.description3', { framework: "Bharatiya Digital Productions is a subsidiary of Gulbadan Talkies Pvt. Ltd., a production company based in Mumbai." })}</p> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="lyt-white">
					<Container>
						<Row>
							<Col lg={8}>
								<h2 className="title wow fadeInUp">{this.props.t("about.title", { framework: "About us" })}</h2>
								<p className="cm-para wow fadeInUp" data-wow-delay="0.1s">
									{this.props.t("about.description", { framework: "Good stories can cross borders and language-barriers. We want to take local content global by making it relevant and relatable, with international quality standards." })}
								</p>
								{/* <p className="cm-para wow fadeInUp" data-wow-delay="0.2s">{this.props.t('about.description2', { framework: "Bharatiya Digital Party (BhaDiPa) - We are completely non-political, undemocratic &amp; politically incorrect, so it's a Party! BhaDiPa is a collective of creators on the cutting-edge of Marathi language content. We make fresh, original, and global-minded stories and live events." })}</p>
                                <p className="wow fadeInUp" data-wow-delay="0.3s">{this.props.t('about.description3', { framework: "Bharatiya Digital Productions is a subsidiary of Gulbadan Talkies Pvt. Ltd., a production company based in Mumbai." })}</p> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="lyt-white">
					<Container>
						<Row>
							<Col lg={12}>
								<h2 className="title wow fadeInUp">{this.props.t("about.team_title", { framework: "Our Team" })}</h2>
							</Col>
						</Row>
						<Row>
							<Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.1s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Aditya} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member1.name", { framework: "Aditya Desai" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member1.role", { framework: "Financial Advisor" })}</h5>
							</Col>

							<Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.2s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Swanand} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member2.name", { framework: "Swanand Tendulkar" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member2.role", { framework: "Events Manager" })}</h5>
							</Col>

							<Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.2s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Chinmay} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member9.name", { framework: "Swanand Tendulkar" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member9.role", { framework: "Events Manager" })}</h5>
							</Col>

							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.3s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Somesh} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member3.name", { framework: "Omkar Jadhav" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member3.role", { framework: "BhaDiPa Programming Head" })}</h5>
							</Col> */}

							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.4s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Abheeraj
} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member4.name", { framework: "Abhay Raut" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member4.role", { framework: "Content Head" })}</h5>
							</Col> */}

							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.5s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Neel} fluid className="pic" />
										<Image onLoad={this.imgLoader} src={AniketJadhav} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member5.name", { framework: "Neel Salekar" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member5.role", { framework: "Social Media Strategist" })}</h5>
							</Col> */}

							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.6s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Shilpa
} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member6.name", { framework: "Prajakta Salbarde" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member6.role", { framework: "Production Head & Admin" })}</h5>
							</Col> */}
							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.6s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Yash
} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member7.name", { framework: "Pooja Parab" })}</h4>
								<h5 className="subhead">{this.props.t(	"about.team_member7.role", { framework: "Social Media Strategist" })}</h5>
							</Col> */}
							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.6s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Rohit} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member8.name", { framework: "" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member8.role", { framework: "" })}</h5>
							</Col> */}
							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.6s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Sujit} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member9.name", { framework: "" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member9.role", { framework: "" })}</h5>
							</Col> */}

							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.7s">
								<figure>
									{" "}
									<LazyLoad height={200}>
										<Image onLoad={this.imgLoader} src={Priya} fluid className="pic" />
									</LazyLoad>
								</figure>
								<h4 className="head">{this.props.t("about.team_member7.name", { framework: "Priya Dixit" })}</h4>
								<h5 className="subhead">{this.props.t("about.team_member7.role", { framework: "Brand Solutions" })}</h5>
							</Col> */}

							{/* <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.2s">
                                <figure> <Image onLoad={this.imgLoader} src={Swanand} fluid className="pic" /></figure>
                                <h4 className="head typ-overflow-non">{this.props.t('about.team_member2.name', { framework: "Swanand Tendulkar" })}</h4>
                                <h5 className="subhead">{this.props.t('about.team_member2.role', { framework: "Events Manager" })}</h5>
                            </Col>
                            <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.3s">
                                <figure> <Image onLoad={this.imgLoader} src={Somesh} fluid className="pic" /></figure>
                                <h4 className="head typ-overflow-non">{this.props.t('about.team_member3.name', { framework: "Omkar Jadhav" })}</h4>
                                <h5 className="subhead">{this.props.t('about.team_member3.role', { framework: "Content Head" })}</h5>
                            </Col>
                            <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.4s">
                                <figure> <Image onLoad={this.imgLoader} src={Abheeraj
} fluid className="pic" /></figure>
                                <h4 className="head typ-overflow-non">{this.props.t('about.team_member4.name', { framework: "Abhay Raut" })}</h4>
                                <h5 className="subhead">{this.props.t('about.team_member4.role', { framework: "Content Head" })}</h5>
                            </Col>
                            <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.5s">
                                <figure> <Image onLoad={this.imgLoader} src={Neel} fluid className="pic" /></figure>
                                <h4 className="head typ-overflow-non">{this.props.t('about.team_member5.name', { framework: "Neel Salekar" })}</h4>
                                <h5 className="subhead">{this.props.t('about.team_member5.role', { framework: "Social Media Strategist" })}</h5>
                            </Col>
                            <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.6s">
                                <figure> <Image onLoad={this.imgLoader} src={Shilpa
} fluid className="pic" /></figure>
                                <h4 className="head typ-overflow-non">{this.props.t('about.team_member6.name', { framework: "Prajakta Salbarde" })}</h4>
                                <h5 className="subhead">{this.props.t('about.team_member6.role', { framework: "Production Head & Admin" })}</h5>
                            </Col>
                            <Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.7s">
                                <figure> <Image onLoad={this.imgLoader} src={Priya} fluid className="pic" /></figure>
                                <h4 className="head typ-overflow-non">{this.props.t('about.team_member7.name', { framework: "Priya Dixit" })}</h4>
                                <h5 className="subhead">{this.props.t('about.team_member7.role', { framework: "Brand Solutions" })}</h5>
                            </Col> */}
						</Row>
					</Container>
				</section>

				<section className="lyt-white typ-enquiries">
					<Container>
						<Row>
							<Col lg={12}>
								<h2 className="title wow fadeInUp">{this.props.t("enquiries.team_title", { framework: "Enquiries" })}</h2>
								<div>
									<div className="allid">
										<ul>
											<li><span>{this.props.t("enquiries.id1", { framework: "General enquiries BhaDiPa" })}	-</span><a href="mailto:bharatiyadigitalparty@gmail.com ">bharatiyadigitalparty@gmail.com </a></li>

											<li><span>{this.props.t("enquiries.id2", { framework: "General enquiries Bha2Pa" })}	- </span><a href="mailto:bha2pa@gmail.com +">bha2pa@gmail.com</a></li>

											<li><span>{this.props.t("enquiries.id3", { framework: "General enquiries Vishay Khol" })} -</span><a href="mailto:bhadipavishaykhol@gmail.com ">bhadipavishaykhol@gmail.com </a></li>

											<li><span>{this.props.t("enquiries.id4", { framework: "Brand enquiries" })} -</span><a href="mailto:brandsolutions@gulbadantalkies.com">brandsolutions@gulbadantalkies.com</a></li>

											<li><span>{this.props.t("enquiries.id5", { framework: "Job inquiries" })} -</span><a href="mailto:jobs.bhadipa@gmail.com">jobs.bhadipa@gmail.com</a></li>	

											<li><span>{this.props.t("enquiries.id6", { framework: "Events" })} -</span><a href="mailto:live@gulbadantalkies.com">live@gulbadantalkies.com</a></li>
										</ul>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="lyt-white honors">
					<Container>
						<Row>
							<Col lg={12}>
								<h2 className="title wow fadeInUp">{this.props.t("about.achievement_title", { framework: "Honors &amp; Awards" })}</h2>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.1s">
									<h3 className="main-head">{this.props.t("about.achievement1.title", { framework: "Most Influential Content Marketing Professional<span> (Top 50)</span>" })}</h3>
									{/* <h3 className="main-head">{this.props.t('about.achievement1.title', { framework: "Most Influential Content Marketing Professional<span className="txt-br"> (Top 50)" })}</span></h3> */}
									<p className="head">
										{this.props.t("about.achievement1.subtitle", { framework: "Top 50 Most influential content marketing professional- for work in content marketing at BhaDiPa" })}
										{/* Nov 2018 &amp; 2019 <span className="txt-br">World Marketing Congress</span> */}
									</p>
									<p>{this.props.t("about.achievement1.description", { framework: "Brand Solutions" })}</p>
								</div>
							</Col>
							<Col lg={6}>
								<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.2s">
									<h3 className="main-head">{this.props.t("about.achievement2.title", { framework: "100 Smartest Digital Marketing Leaders" })}</h3>
									<p className="head">{this.props.t("about.achievement2.subtitle", { framework: "Feb 2019 - The World Digital Marketing Congress" })}</p>
								</div>
							</Col>
							<Col lg={6}>
								<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.3s">
									<h3 className="main-head">{this.props.t("about.achievement3.title", { framework: "Best Web Series" })}</h3>
									<p className="head">{this.props.t("about.achievement3.subtitle", { framework: "Aug 2018- Zee Talkies Comedy Awards" })}</p>
									<p>{this.props.t("about.achievement3.description", { framework: "Bhadipa won best marathi web series for “Aai & Me” at the Zee Talkies Comedy Awards 2018." })}</p>
								</div>
							</Col>
							<Col lg={6}>
								<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.4s">
									<h3 className="main-head">{this.props.t("about.achievement4.title", { framework: "Citizen Media & Journalism<span > - Special Mention</span>" })}</h3>
									{/* <h3 className="main-head">{this.props.t('about.achievement4.title', { framework: "Citizen Media & Journalism<span className="txt-br"> - Special Mention</span>" })}</h3> */}
									<p className="head">{this.props.t("about.achievement4.subtitle", { framework: "May 2018 Social Media for Empowerment Awards (SM4E)" })}</p>
									<p>{this.props.t("about.achievement4.description", { framework: "Social Media for Empowerment Awards recognized BhaDiPa as a contributor to Citizen Media because of its pioneering efforts to create contemporary and meaningful entertainment in the regional language of Marathi." })}</p>
								</div>
							</Col>

							<Collapse in={this.state.showText}>
								<Row>
									<Col lg={6}>
										<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.5s">
											<h3 className="main-head">{this.props.t("about.achievement5.title", { framework: "Digital Content Company of the Year - Regional" })}</h3>
											<p className="head">{this.props.t("about.achievement5.subtitle", { framework: "Mar 2018 - Talent Track Awards" })}</p>
											<p>{this.props.t("about.achievement5.description", { framework: "For the first time, Talent Track Awards introduced a new category for Digital Content Company of the Year, recognizing Regional Languages." })}</p>
										</div>
									</Col>
									<Col lg={6}>
										<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.6s">
											<h3 className="main-head">{this.props.t("about.achievement6.title", { framework: "Best Music Video Award - Silver" })}</h3>
											<p className="head">{this.props.t("about.achievement6.subtitle", { framework: "Nov 2017 - India Film Project" })}</p>
											<p>{this.props.t("about.achievement6.description", { framework: "Best Of Digital - Music Videos: Silver Award for General Alert - Nidak, directed by Sarang Sathaye" })}</p>
										</div>
									</Col>
									<Col lg={6}>
										<div className="bs-box middle cm-shadow wow fadeInUp" data-wow-delay="0.7s">
											<h3 className="main-head">{this.props.t("about.achievement7.title", { framework: "Best Web Content" })}</h3>
											<p className="head">{this.props.t("about.achievement7.subtitle", { framework: "Jul 2017 - Zee Marathi Comedy Awards - As the pioneers in the Marathi digital entertainment" })}</p>
											<p>{this.props.t("about.achievement7.description", { framework: "space, BhaDiPa was recognized with the introduction of a new category in the Zee Marathi Comedy Awards." })}</p>
										</div>
									</Col>
								</Row>
							</Collapse>
							<Col lg={12} className="txt-center wow fadeInUp">
								{/* <a href="#" className="cm-btn yellow bg-shadow">See More</a> */}
								<a onClick={() => this.setState({ showText: !this.state.showText })} className="cm-btn yellow bg-shadow">
									{this.state.showText ? this.props.t("about.see_less", { framework: "See Less" }) : this.props.t("about.see_more", { framework: "See More" })}
								</a>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="lyt-social">
					<Container fluid={true}>
						<Row>
							<Col md={12} lg={12} className="bg-base">
								<h2 className="title wow fadeInUp footer-text" data-wow-delay="0.1s">
									{this.props.t("social.watch", { framework: "Watch us on" })}
								</h2>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ" data-wow-delay="0.2s">
									<figure>
										<Image src={youtube} fluid />
									</figure>
								</a>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BhaDiPa/" data-wow-delay="0.3s">
									<figure>
										<Image src={facebook} fluid />
									</figure>
								</a>
								<Instagram />
								<a href="/contact" className="cm-btn bg-shadow wow fadeInUp" data-wow-delay="0.5s">
									{this.props.t("social.get", { framework: "Get In Touch" })}
								</a>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default withTranslation("common")(About);
