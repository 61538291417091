import React from "react";
import WOW from "wow.js";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import SearchSwiper from "../module/SearchSwiper";
import { showSearch, searchTerm } from "../module/Notify";

class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: "en",
			SearchData: null,
			PlaylistData: null,
			VideoData: null,
			ChannelData: null,
			term: "",
			showSearch: true,
		};
	}

	UNSAFE_componentWillMount() {
		window.addEventListener("searchTerm", (e) => {
			if (e.detail != null && e.detail != "") {
				// console.log(e.detail.length)
				this.setState({
					SearchData: null,
					ChannelData: null,
					PlaylistData: null,
					VideoData: null,
					term: e.detail,
				});
			}
		});
		if (window.localStorage.getItem("language") != null) {
			const getLang = window.localStorage.getItem("language") || "en";
			this.changeLang(getLang);
			this.setState({
				lang: getLang,
			});
		}

		this.getLanguage();
	}

	fetchSearchPageData = (lang) => {
		if (this.state.term.length >= 3) {
			var networkHelper = new NetworkHelper();
			networkHelper.setUrl(Constants.get_search_page_data);
			networkHelper.setMethod("POST");
			networkHelper.setData(
				JSON.stringify({
					lang_code: lang,
					term: this.state.term,
				})
			);
			networkHelper.execute(
				(response) => {
					// console.log("Home Page Data >>>", response.data)
					if (response.data.data != null) {
						this.setState({
							SearchData: response.data.data,
							ChannelData: response.data.data.channel,
							PlaylistData: response.data.data.playlist,
							VideoData: response.data.data.video,
						});
					}
				},
				function (errorMsg, StatusCode) {
					console.log(errorMsg, StatusCode);
				},
				function () {}
			);
		}
	};
	// Event emiter for language changed
	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			const { i18n } = this.props;
			this.setState({
				lang: e.detail,
			});
			i18n.changeLanguage(e.detail);
			this.fetchSearchPageData(e.detail);
		});
	}

	//Change the lang using constructor
	changeLang = (val) => {
		const { i18n } = this.props;
		i18n.changeLanguage(val);
		this.fetchSearchPageData(val);
	};

	handleSearchBox = () => {
		this.setState({
			showSearch: !this.state.showSearch,
		});
		showSearch(this.state.showSearch);
	};
	render() {
		const { SearchData, ChannelData, PlaylistData, VideoData, term } = this.state;
		return (
			<Container fluid style={{ backgroundColor: "grey" }}>
				<FormControl
					type="text"
					placeholder="Find videos, channels, series, music & more..."
					className="mr-sm-2"
					onChange={(e) => {
						searchTerm(e.target.value);
						this.setState({
							term: e.target.value,
						});
					}}
				/>

				<i
					className="icon-search"
					onClick={() => {
						this.handleSearchBox();
					}}
				></i>
				<div>
					{term.length < 3 ? (
						<Container>
							<Row>
								<Col className="txt-center" md={12}>
									<h1>Enter the keyword to Search</h1>
									<p>(Upto 3 letters)</p>
								</Col>
							</Row>
						</Container>
					) : null}
					{SearchData == null && term.length >= 3 ? (
						<Container>
							<Row>
								<Col className="txt-center" md={12}>
									<h1>No Results Found</h1>
									<p>(Search another keyword)</p>
								</Col>
							</Row>
						</Container>
					) : null}
					{/* Channel Data */}
					{ChannelData != null && ChannelData != "undefined" && SearchData != null ? <SearchSwiper class="lyt-swiper theme" swip="right" data={ChannelData} lang={this.state.lang} type="1" /> : null}

					{/* Playlist Data */}

					{PlaylistData != null && PlaylistData != "undefined" && SearchData != null ? <SearchSwiper class="lyt-swiper white" swip="left" data={PlaylistData} lang={this.state.lang} type="2" /> : null}

					{/* Video Data */}
					{VideoData != null && VideoData != "undefined" && SearchData != null ? <SearchSwiper class="lyt-swiper base" swip="right" data={VideoData} lang={this.state.lang} type="3" /> : null}
				</div>
			</Container>
		);
	}
}

export default withTranslation("common")(Search);
