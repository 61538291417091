import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";

class Feedback extends Component {
	constructor() {
		super();
		this.state = {
			fields: {},
			errors: {},
		};
		// this.onchange = this.onchange.bind(this)
		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
	}

	submitForm = () => {
		console.log("Submit");
		// e.preventDefault();
		var networkHelper = new NetworkHelper();
		networkHelper.setUrl(Constants.contactus);
		networkHelper.setMethod("POST");
		networkHelper.setData(
			JSON.stringify({
				name: this.state.fields.name,
				email: this.state.fields.email,
				mobile_no: this.state.fields.mobile_no,
				feedback: this.state.fields.feedback,
			})
		);
		console.log("state Data >>>", this.name);
		networkHelper.execute(
			(response) => {
				console.log("response Data >>>", response.data);
				this.setState({});
			},
			function (errorMsg, StatusCode) {
				console.log(errorMsg);
			},
			function () {}
		);
	};

	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
		// this.submitForm();
	}

	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	submituserRegistrationForm(e) {
		e.preventDefault();
		if (this.validateForm()) {
			let fields = {};
			fields["name"] = "";
			fields["email"] = "";
			fields["mobile_no"] = "";
			fields["feedback"] = "";
			this.setState({ fields: fields });
			this.submitForm();
			alert("Form submitted");
		}
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["name"]) {
			formIsValid = false;
			errors["name"] = "false";
		}

		if (typeof fields["name"] !== "undefined") {
			if (!fields["name"].match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)) {
				formIsValid = false;
				errors["name"] = "false";
			}
		}

		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "false";
		}

		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["email"])) {
				formIsValid = false;
				errors["email"] = "false";
			}
		}

		if (!fields["mobile_no"]) {
			formIsValid = false;
			errors["mobile_no"] = "false";
		}

		if (typeof fields["mobile_no"] !== "undefined") {
			if (!fields["mobile_no"].match(/^[0-9]{10}$/)) {
				formIsValid = false;
				errors["mobile_no"] = "false";
			}
		}

		if (!fields["feedback"]) {
			formIsValid = false;
			errors["feedback"] = "false";
		}

		if (typeof fields["feedback"] !== "undefined") {
			//not null
			if (!fields["feedback"].match(/^$|\s*/)) {
				formIsValid = false;
				errors["feedback"] = "false";
			}
		}

		this.setState({
			errors: errors,
		});
		return formIsValid;
	}

	render() {
		return (
			<div className="bs-form typ-contact-forms">
				<Form onSubmit={this.submituserRegistrationForm}>
					<Row>
						<Col md={12} className="m-b-40">
							<Form.Control className="inpt-p-top-0" type="text" placeholder="Name" name="name" value={this.state.fields.name} onChange={this.handleChange} isValid={this.state.errors.name === "true"} isInvalid={this.state.errors.name === "false"} />
							{this.state.errors.name === "false" ? <label>Enter the name</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control type="email" name="email" placeholder="Email Id" value={this.state.fields.email} onChange={this.handleChange} isValid={this.state.errors.email === "true"} isInvalid={this.state.errors.email === "false"} />
							{this.state.errors.email === "false" ? <label>Enter valid email</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control type="text" placeholder="Mobile No" name="mobile_no" value={this.state.fields.mobile_no} onChange={this.handleChange} isValid={this.state.errors.mobile_no === "true"} isInvalid={this.state.errors.mobile_no === "false"} />
							{this.state.errors.mobile_no === "false" ? <label>Enter valid number</label> : ""}
						</Col>
						<Col md={12}>
							<Form.Control as="textarea" rows="1" placeholder="Feedback" name="feedback" className="last" value={this.state.fields.feedback} onChange={this.handleChange} isValid={this.state.errors.feedback === "true"} isInvalid={this.state.errors.feedback === "false"} />
							{this.state.errors.feedback === "false" ? <label>Please provide feedback</label> : ""}
						</Col>
						<Button type="submit" onSubmit={this.submitForm} className="ctm-btn">
							<i class="icon icon-plane"></i>
						</Button>
					</Row>
				</Form>
			</div>
		);
	}
}
export default Feedback;
