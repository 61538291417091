import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Constants from "../module/constants";
import NetworkHelper from "../../helpers/NetworkHelper";
import { HiDocumentRemove } from "react-icons/hi";
class Careers extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm =
      this.submituserRegistrationForm.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    // this.fileUpload = this.fileUpload.bind(this);
  }
  // file upload onchange() and fielupload
  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
    console.log("File Upload : ", { file: e.target.files[0] });
  }

  removeFile() {
    this.setState({ file: null });
  }

  // fileUpload(file) {
  // 	const url = "./uploads";
  // 	const formData = new FormData();
  // 	formData.append("file", file);
  // 	const config = {
  // 		headers: {
  // 			"content-type": "multipart/form-data",
  // 		},
  // 	};
  // 	return post(url, formData, config);
  // }

  submitForm = () => {
    console.log(this.state);
    let formData = new FormData();
    formData.append("name", this.state.fields.name);
    formData.append("email", this.state.fields.email);
    formData.append("mobile_no", this.state.fields.mobile_no);
    formData.append("position", this.state.fields.position);
    formData.append("Cv", this.state.file); // Assuming this is the file input field
    formData.append("about", this.state.fields.about);
    console.log("Submit");
    // e.preventDefault();
    var networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.career);
    networkHelper.setMethod("POST");
    networkHelper.setData(formData);

    console.log("state Data >>>", formData);
    networkHelper.execute(
      (response) => {
        console.log("response Data >>>", response.data);
        this.setState({});
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg);
      },
      function () {}
    );
  };

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    // this.submitForm();
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();

    const { file } = this.state;

    // if (file) {
    if (this.validateForm()) {
      let fields = {};
      fields["name"] = "";
      fields["email"] = "";
      fields["mobile_no"] = "";
      fields["position"] = "";
      this.file = null;
      fields["about"] = "";
      this.setState({
        fields: fields,
        file: this.state.file,
      });

      this.submitForm();
      this.removeFile();
      alert("Form submitted");
    }
    // } else {
    //   // File not selected, you can show an error message or take other action
    //   console.error("File not selected. Please select a file.");
    // }
  }

  validateForm() {
    let fields = this.state.fields;
    let file = this.state.file;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "false";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)) {
        formIsValid = false;
        errors["name"] = "false";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "false";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "false";
      }
    }

    if (!fields["mobile_no"]) {
      formIsValid = false;
      errors["mobile_no"] = "false";
    }

    if (typeof fields["mobile_no"] !== "undefined") {
      if (!fields["mobile_no"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobile_no"] = "false";
      }
    }

    if (!fields["position"]) {
      formIsValid = false;
      errors["position"] = "false";
    }

    if (typeof fields["position"] !== "undefined") {
      //not null
      if (!fields["position"].match(/^$|\s*/)) {
        formIsValid = false;
        errors["position"] = "false";
      }
    }

    if (!fields["about"]) {
      formIsValid = false;
      errors["about"] = "false";
    }

    if (typeof fields["about"] !== "undefined") {
      //not null
      if (!fields["about"].match(/^$|\s*/)) {
        formIsValid = false;
        errors["about"] = "false";
      }
    }

    if (!file) {
      formIsValid = false;
      errors["Cv"] = "false";
    }

    // if (typeof fields["Cv"] !== "undefined") {
    //   //not null
    //   if (!fields["Cv"].match(/^$|\s*/)) {
    //     formIsValid = false;
    //     errors["Cv"] = 'false';
    //   }
    // }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    const { file } = this.state;
    // console.log("Statwe", this.state.fields.name)
    return (
      <div className="bs-form typ-contact-forms">
        <Form onSubmit={this.submituserRegistrationForm}>
          <Row>
            <Col md={12} className="m-b-40">
              <Form.Control
                className="inpt-p-top-0"
                type="NAME"
                placeholder="Name"
                name="name"
                value={this.state.fields.name}
                onChange={this.handleChange}
                isValid={this.state.errors.name === "true"}
                isInvalid={this.state.errors.name === "false"}
                autocom
              />
              {this.state.errors.name === "false" ? (
                <label>Enter the name</label>
              ) : (
                ""
              )}
            </Col>
            <Col md={12} className="m-b-40">
              <Form.Control
                type="email"
                placeholder="Email Id"
                name="email"
                value={this.state.fields.email}
                onChange={this.handleChange}
                isValid={this.state.errors.email === "true"}
                isInvalid={this.state.errors.email === "false"}
              />
              {this.state.errors.email === "false" ? (
                <label>Enter valid email</label>
              ) : (
                ""
              )}
            </Col>
            <Col md={12} className="m-b-40">
              <Form.Control
                type="text"
                placeholder="Mobile No"
                name="mobile_no"
                value={this.state.fields.mobile_no}
                onChange={this.handleChange}
                isValid={this.state.errors.mobile_no === "true"}
                isInvalid={this.state.errors.mobile_no === "false"}
              />
              {this.state.errors.mobile_no === "false" ? (
                <label>Enter valid number</label>
              ) : (
                ""
              )}
            </Col>
            <Col md={12} className="m-b-40">
              <Form.Control
                type="text"
                placeholder="Applying for"
                name="position"
                value={this.state.fields.position}
                onChange={this.handleChange}
                isValid={this.state.errors.position === "true"}
                isInvalid={this.state.errors.position === "false"}
              />
              {this.state.errors.position === "false" ? (
                <label>Enter position</label>
              ) : (
                ""
              )}
            </Col>
            <Col md={12} className="m-b-40">
              <div className="upload-files">
                {/* <label for="file-upload" name="Cv" class="custom-file-upload" >Upload CV</label>  */}
                <label>Upload Resume</label>
                <Form.Control
                  type="file"
                  onChange={this.onChangeFile}
                  id="file-upload"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  isValid={this.state.errors.name === "true"}
                  isInvalid={this.state.errors.name === "false"}
                />
                {file && (
                  <div className="file-info">
                    <span className="file-name">{file.name}</span>
                    <span className="icon" onClick={this.removeFile}>
                      <HiDocumentRemove />
                      {/* <i class="icon icon-plane"></i> */}
                    </span>
                  </div>
                )}
              </div>
              {this.state.errors.Cv === "false" ? (
                <label>Select the file you want to upload.</label>
              ) : (
                ""
              )}
            </Col>
            <Col md={12}>
              <Form.Control
                as="textarea"
                rows="1"
                placeholder="Tell us about yourself"
                className="last"
                name="about"
                value={this.state.fields.about}
                onChange={this.handleChange}
                isValid={this.state.errors.about === "true"}
                isInvalid={this.state.errors.about === "false"}
              />
              {this.state.errors.about === "false" ? (
                <label>
                  Let us know more about you! Write a brief description.
                </label>
              ) : (
                ""
              )}
            </Col>
            <Button type="submit" className="ctm-btn">
              <i class="icon icon-plane"></i>
            </Button>
          </Row>
        </Form>
      </div>
    );
  }
}
export default Careers;
