import React, { Component } from "react";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import Loginform from "./../form/Login-form";
import Signform from "./../form/Sign-form";
import { Container, Row, Col, Image } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import Instagram from "../../common/Instagram";

class Login extends Component {
	constructor() {
		super();
		this.state = {
			active: false,
		};
		this.getSigninScreen = this.getSigninScreen.bind(this);
		this.getSignupScreen = this.getSignupScreen.bind(this);
	}
	UNSAFE_componentWillMount() {}

	getSignupScreen() {
		console.log("getSignupScreen");
		this.setState({
			active: true,
		});
	}

	getSigninScreen() {
		console.log("getSigninScreen");

		this.setState({
			active: false,
		});
	}

	render() {
		return (
			<div>
				<section className="lyt-white mod-login">
					<Container>
						<Row className="mob-btn-row">
							<Col xs={6} className="cm-border-right pad-lef">
								<button className={this.state.active === false ? "mob-btn " : "mob-btn active"} onClick={this.getSigninScreen} onClick={this.getSignupScreen}>
									Sign In
								</button>
							</Col>
							<Col xs={6} className="pad-rig">
								<button className={this.state.active === false ? "mob-btn active" : "mob-btn"} onClick={this.getSigninScreen}>
									Sign Up
								</button>
							</Col>
						</Row>
						<Row className={this.state.active === false ? "ctm-container" : "ctm-container container right-panel-active"} id="container">
							{/* Sign up */}

							<div className={this.state.active === false ? "form-container sign-up-container bx-base" : "form-container sign-up-container bx-base ctm-hide"}>
								<h2 className="title wow fadeInUp">Sign Up</h2>
								<a href="#" className="ctm-block">
									<i className="icon icon-facebook"></i>or use your account
								</a>
								<Signform />
							</div>

							{/* Signin  */}

							<div className={this.state.active === false ? "form-container sign-in-container bx-base ctm-hide" : "form-container sign-in-container bx-base"}>
								<h2 className="title wow fadeInUp">Log In</h2>
								<a href="#" className="ctm-block">
									<i className="icon icon-facebook"></i>or use your account
								</a>
								<Loginform />
							</div>
							<div className="txt-center overlay-container">
								<div className="overlay">
									<div className="overlay-panel overlay-left">
										<h2 className="title wow fadeInUp">Welcome back</h2>
										<p>To keep connected with us please login with your personal info</p>

										<button className="ghost" onClick={this.getSigninScreen}>
											Sign In
										</button>
									</div>
									<div className="overlay-panel overlay-right">
										<h2 className="title wow fadeInUp">Welcome back</h2>
										<p>To keep connected with us please login with your personal info</p>

										<button className="ghost" onClick={this.getSignupScreen}>
											Sign Up
										</button>
									</div>
								</div>
							</div>
						</Row>
					</Container>
				</section>
				<section className="lyt-social">
					<Container fluid={true}>
						<Row>
							<Col md={12} lg={12} className="bg-base">
								<h2 className="title wow fadeInUp" data-wow-delay="0.1s">
									Watch us on
								</h2>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ" data-wow-delay="0.2s">
									<figure>
										<LazyLoad height={200}>
											<Image src={youtube} fluid />
										</LazyLoad>
									</figure>
								</a>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BhaDiPa/" data-wow-delay="0.3s">
									<figure>
										<LazyLoad height={200}>
											<Image src={facebook} fluid />
										</LazyLoad>
									</figure>
								</a>
								<Instagram />
								<a href="/contact" className="cm-btn bg-shadow wow fadeInUp" data-wow-delay="0.5s">
									Get In Touch
								</a>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default Login;
