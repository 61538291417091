import React from "react";
const VideoListItem = (props) => {
	// console.log("VideoListItem", props.video)
	const video = props.video;
	const onUserSelected = props.onUserSelected;
	// console.log(video);
	// const imageUrl = video.snippet.thumbnails.default.url;
	const imageUrl = video.img;

	return (
		<li onClick={() => onUserSelected(video)} className="list-group-item">
			<div className="video-list media">
				<div className="media-left">
					<img className="media-object" height="100%" width="100%" src={imageUrl} alt="youtube thumnail" />
					{/* <img className="media-object" height="100%" width="100%" src={"https://via.placeholder.com/300x250"} /> */}
				</div>
				<div className="media-body">
					<div className="media-heading series-heading">
						{video.title.length > 40 ? <p>{`${video.title.substring(0, 40)}...`}</p> : <p>{video.title}</p>}
						{/* {video.title} */}
					</div>
				</div>
			</div>
		</li>
	);
};

export default VideoListItem;
