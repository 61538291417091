import React, { Component } from "react";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { Container, Row, Col, Image } from "react-bootstrap";
import videoPlaceHolder from "../../images/video-placeholder.png";
import { Image as ShimmerImage } from "react-shimmer";
class Webseries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WebSeriesData: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (window.localStorage.getItem("language") !== null) {
      const getLang = window.localStorage.getItem("language") || "en";
      this.changeLang(getLang);
      this.setState({
        lang: getLang,
      });
    }
    this.getLanguage();
  }

  changeLang = (val) => {
    const { t, i18n } = this.props;
    i18n.changeLanguage(val);
    this.fetchWebseriesPageData(val);
  };

  // Event emiter for language changed
  getLanguage() {
    window.addEventListener("filterLanguage", (e) => {
      const { t, i18n } = this.props;
      if (e.detail != null) {
        this.setState({
          lang: e.detail,
        });
      }
      i18n.changeLanguage(e.detail);
      this.fetchWebseriesPageData(e.detail);
    });
  }

  fetchWebseriesPageData = (lang) => {
    let networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.get_webseries_page_data);
    networkHelper.setMethod("POST");
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      })
    );
    networkHelper.execute(
      (response) => {
        this.setState({
          WebSeriesData: response.data,
        });
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg, StatusCode);
      },
      function () {}
    );
  };
  render() {
    // const count = this.state.WebSeriesData.data != null ? this.state.WebSeriesData.data.length / 2 : 0;
    return (
      <div>
        <section className="honors">
          <Container>
            <section className="mod-webseries">
              <Container>
                <Row>
                  <Col m={12}>
                    <h2 className="title wow fadeIn">
                      {this.props.t("home.web", { framework: "web Series" })}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  {this.state.WebSeriesData.data != null ? (
                    Object.keys(this.state.WebSeriesData.data).reverse().map((id) => (
                      <Col lg={6} style={{ marginBottom: 50 }}>
                        <Row className="series-slide">
                          <Col lg={6}>
                            <figure
                              className="wow fadeInUp"
                              data-wow-delay="0.1s"
                            >
                              <LazyLoad height={200}>
                                <ShimmerImage
                                  fallback={
                                    <Image
                                      src={videoPlaceHolder}
                                      fluid
                                      className="series-pic"
                                    />
                                  }
                                  NativeImgProps={{
                                    className: "img-fluid series-pic",
                                  }}
                                  src={
                                    this.state.lang == "en"
                                      ? this.state.WebSeriesData.data[id].banner
                                          .en
                                      : this.state.WebSeriesData.data[id].banner
                                          .ma
                                  }
                                />
                              </LazyLoad>
                            </figure>
                          </Col>
                          <Col lg={6}>
                            <div className="series-decp">
                              <h5 className="wow fadeInUp">
                                {this.state.WebSeriesData.data[id].title}
                              </h5>
                              <p className="wow fadeInUp" data-wow-delay="0.2s">
                                {this.state.WebSeriesData.data[id].description}
                              </p>
                              <a
                                href={
                                  this.state.WebSeriesData.data[id]
                                    .external_link
                                }
                                className="cm-btn wow fadeInUp"
                                data-wow-delay="0.3s"
                              >
                                {this.props.t("web.check", {
                                  framework: "Check now",
                                })}
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))
                  ) : (
                    <div></div>
                  )}
                </Row>
              </Container>
            </section>
          </Container>
        </section>
      </div>
    );
  }
}
export default withTranslation("common")(Webseries);
