import React from "react";
import { Link } from "react-router-dom";

import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import { Container, Row, Col, Image } from "react-bootstrap";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import WOW from "wow.js";
import { withTranslation } from "react-i18next";
import SwiperComedian from "../module/Swiper-comedian";
import { changeTabFilter } from "../module/Notify";
import LazyLoad from "react-lazyload";
import Instagram from "../../common/Instagram";

class Comedians extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ComediansData: [],
            lang: 'en'
        }
        this.changeTab = this.changeTab.bind(this);
    }

    changeTab = (value) => {
        changeTabFilter(value);
        window.localStorage.setItem('tab', value)
    }

    //Change the lang using constructor
    changeLang = (val) => {
        const { t, i18n ,} = this.props;
        i18n.changeLanguage(val);
        this.getComediansData(val);
    }
    componentDidMount() {
        const wow = new WOW().init();
        if (!window.localStorage.getItem('language') != null) {
            const getLang = window.localStorage.getItem('language') || "en";
            // this.getComediansData(getLang);
            this.changeLang(getLang);
            this.setState({
                lang: getLang
            })
        }
        this.getLanguage()

    }

    getLanguage() {
        window.addEventListener('filterLanguage', (e) => {
            const { t, i18n } = this.props;
            console.log("t>>>>>>>>>>", t)
            i18n.changeLanguage(e.detail);
            this.getComediansData(e.detail);
        })
    }


    getComediansData(lang) {
        var networkHelper = new NetworkHelper();
        networkHelper.setUrl(Constants.get_comedians_page_data);
        networkHelper.setMethod("POST");
        networkHelper.setData(JSON.stringify({
            'lang_code': lang
        }))
        networkHelper.execute((response) => {
            // console.log("Comedian Data >>>", response.data)
            this.setState({
                ComediansData: response.data,
            })
        }, function (errorMsg, StatusCode) {
            console.log(errorMsg)

        }, function () {
        });

    }

    render() {
        const { ComediansData } = this.state;
        return (
            <div>
                <section className="lyt-base">
                    <Container className="content">
                        <Row>
                            <Col lg={10} >
                                <div className="bx-white">
                                    <h2 className="title wow fadeInUp" data-wow-delay="0.1s"> {this.props.t('comedians.title', { framework: "Its all about our Comedians" })}</h2>
                                    <p className="wow fadeInUp" data-wow-delay="0.2s">{this.props.t('comedians.description', { framework: "BhaDiPa manages a variety of stand-up comedians. Contact us to see which comedians will be most suitable for your events. Currently BhaDiPa has a network of a 100+ comics from all over Maharashtra and manages 18 comics and regularly organises curated open mic shows and thematic shows to give the Marathi comics a platform." })}</p>
                                    <Link to="/contact" onClick={() => this.changeTab('collaborate')} className="cm-btn yellow bg-shadow wow fadeInUp" data-wow-delay="0.3s">{this.props.t('comedians.book_btn', { framework: "Book a comic" })}</Link>

                                </div>
                            </Col>
                        </Row>
                        <SwiperComedian lang={this.state.lang} comedians={ComediansData.comedians} />
                        
                    </Container>
                </section>
                <section>
                    <Container fluid={true} className="lyt-social">
                        <Row>
                            <Col md={12} lg={12} className="bg-base">

                                <h2 className="title wow fadeInUp" data-wow-delay="0.1s">{this.props.t('social.watch', { framework: "Watch us on" })}</h2>
                                <a className="fluid-icon wow fadeInUp" target="_blank" href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ" data-wow-delay="0.2s">
                                    <figure><Image src={youtube} fluid /></figure>
                                </a>
                                <a className="fluid-icon wow fadeInUp" target="_blank" href="https://www.facebook.com/BhaDiPa/" data-wow-delay="0.3s">
                                    <figure><Image src={facebook} fluid /></figure>
                                </a>
                                <Instagram />
                                <a href="/contact" className="cm-btn bg-shadow wow fadeInUp" data-wow-delay="0.5s">{this.props.t('social.get', { framework: "Get In Touch" })}</a>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </div>


        );
    }
}

export default withTranslation("common")(Comedians);
